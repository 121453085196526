<header tuiHeader>
	<h3 tuiTitle class="margin-0">{{ 'media.file-image' | transloco }}</h3>

	<div tuiAccessories>
		<button tuiIconButton size="m" (click)="onDismiss()" appearance="ghost">
			<tui-icon icon="@tui.x"></tui-icon>
		</button>
	</div>
</header>

<div class="image-cropper-dialog__content">
	@if (error) {
		<tui-notification appearance="error">
			{{ 'form.error.image-cropper.load' | transloco }}
		</tui-notification>
	} @else if (imageFile) {
		@if (loading) {
			<div class="image-cropper-dialog__content-loading">
				<tui-loader size="xl" />
			</div>
		}

		<image-cropper
			[imageFile]="imageFile"
			[maintainAspectRatio]="true"
			[aspectRatio]="1 / 1"
			format="png"
			[disabled]="loading"
			(imageCropped)="onImageCropped($event)"
			(imageLoaded)="onImageLoaded()"
			(cropperReady)="onCropperReady()"
			(loadImageFailed)="onLoadImageFailed()"
		></image-cropper>
	}
</div>

<footer tuiFooter>
	<button tuiButton appearance="outline" (click)="onDismiss()">
		{{ 'action.cancel' | transloco }}
	</button>
	<button
		tuiButton
		appearance="accent"
		(click)="onSave()"
		[disabled]="loading || error"
	>
		{{ 'action.save' | transloco }}
	</button>
</footer>
