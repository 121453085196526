import { HttpClient } from '@angular/common/http';
import { inject } from '@angular/core';
import {
	collection,
	collectionData,
	CollectionReference,
	doc,
	documentId,
	DocumentReference,
	Firestore,
	getDoc,
	getDocs,
	orderBy,
	query,
	QueryConstraint,
	setDoc,
	UpdateData,
	updateDoc,
	where,
	WithFieldValue,
	writeBatch,
} from '@angular/fire/firestore';
import { ActivityService } from '@context/frontend/activity';
import { Activity, ActivityType } from '@context/shared/types/activity';
import { Entity } from '@context/shared/types/common';
import { SortDir } from '@context/shared/types/pagination';
import { User } from '@context/shared/types/user';
import { sanitize } from '@context/shared/utils';
import { Timestamp } from 'firebase/firestore';
import { combineLatest, Subject } from 'rxjs';
import { v7 } from 'uuid';
import { API_URL, ORGANIZATION_ID, USER_ID } from '../tokens';

const MaxBatch = 500;
const DefaultConfig = {
	useOrganization: true,
	version: 'v1',
};

export abstract class CrudService<T extends Entity> {
	collection!: CollectionReference<T, T>;

	protected readonly items = new Map<string, T>();

	protected readonly api = inject(API_URL);

	protected readonly firestore = inject(Firestore);
	protected readonly organizationId = inject(ORGANIZATION_ID);
	protected readonly userId = inject(USER_ID);
	protected readonly http = inject(HttpClient);
	protected readonly activityService = inject(ActivityService);

	/**
	 * emits whenever a change event occurs locally. That way if a modal or some out of scope
	 * service/component creates content we can have other components/table listen for it
	 * to know when we need to reload.
	 */
	readonly changed$ = new Subject<void>();

	readonly reset$ = new Subject<boolean>();

	private userRef: DocumentReference<User> | null = null;

	constructor(
		protected path?: string,
		protected readonly config = DefaultConfig,
	) {
		this.setupInitializing();
	}

	setupInitializing() {
		combineLatest([this.organizationId, this.userId]).subscribe(() => {
			// resets when the organization id and user id have been updated
			this.reset$.next(true);
			this.initialize();
		});
	}

	protected initialize() {
		if (!this.path) return;

		if (this.config.useOrganization) {
			if (!this.organizationId.value) return;

			this.collection = collection(
				this.firestore,
				`organizations/${this.organizationId.value}/${this.path}`,
			) as CollectionReference<T, T>;
		} else {
			this.collection = collection(
				this.firestore,
				`${this.path}`,
			) as CollectionReference<T, T>;
		}

		if (this.userId.value && this.organizationId.value) {
			this.userRef = doc(
				collection(
					this.firestore,
					`organizations/${this.organizationId.value}/users`,
				),
				this.userId.value,
			) as DocumentReference<User>;
		}
	}

	async create(payload: Partial<T>) {
		const now = Timestamp.now();
		payload.createdAt = now;
		payload.updatedAt = now;
		payload.deletedAt = null;

		payload.createdBy = this.userRef ?? undefined;

		payload.id = v7();

		const docRef = doc(this.collection, payload.id);
		// we use `setDoc` instead of `addDoc` to use our own `id` value.
		await setDoc(docRef, sanitize<WithFieldValue<Partial<T>>>(payload));

		if (this.userId.value) {
			await this.logActivity(
				docRef,
				{ type: 'create' },
				this.userRef ?? this.userId.value,
			);
		}

		this.changed$.next();
		return payload;
	}

	async update(
		payload: Partial<T & Entity>,
		options: UpdateOptions,
	): Promise<Partial<T>> {
		payload.updatedAt = Timestamp.now();

		const docRef = doc(this.collection, payload.id);
		const value = sanitize(payload);
		await updateDoc(
			doc(this.collection, payload.id),
			value as UpdateData<T>,
		);

		if (this.userId.value && options.addActivityLog) {
			await this.logActivity(
				docRef,
				{ type: options.type },
				this.userRef ?? this.userId.value,
			);
		}

		this.changed$.next();
		return value;
	}

	async delete(id: string) {
		const docRef = doc(this.collection, id);
		await updateDoc(
			docRef,
			sanitize({
				deletedAt: Timestamp.now(),
			}) as UpdateData<T>,
		);

		if (this.userId.value) {
			await this.logActivity(
				docRef,
				{ type: 'delete' },
				this.userRef ?? this.userId.value,
			);
		}

		this.changed$.next();
		// empty object to ensure deletion success
		return {};
	}

	/**
	 * Batch creates multiple entries at the same time.
	 *
	 * @param payloads The collection of entries to create.
	 */
	batchCreate(payloads: (T & Entity)[]) {
		if (payloads.length > MaxBatch) {
			// todo: setup true batching on limits
			throw new Error(
				'Attempting to batch update more than 500 records.',
			);
		}

		const batch = writeBatch(this.firestore);
		const activityTasks: Promise<Activity>[] = [];

		const now = Timestamp.now();
		payloads.forEach((payload) => {
			payload.createdAt = now;
			payload.updatedAt = now;
			payload.deletedAt = null;
			payload.id = v7();

			const docRef = doc(this.collection, payload.id);
			batch.set(docRef, sanitize(payload));
			if (this.userId.value) {
				activityTasks.push(
					this.logActivity(
						docRef,
						{ type: 'create' },
						this.userRef ?? this.userId.value,
					),
				);
			}
		});

		// we need the activity promises to complete AFTER the entity actions occur or
		// a race case could occur and we add notifications to an object that doesn't
		// exist yet
		return batch.commit().then(() => {
			// we don't need to wait for this to complete
			Promise.all(activityTasks);
			this.changed$.next();
			return payloads;
		});
	}

	/**
	 * Batch updates multiple entries at the same time.
	 *
	 * @param payloads The collection of entries to create.
	 */
	batchUpdate(payloads: Partial<T & Entity>[], options: UpdateOptions) {
		if (payloads.length > MaxBatch) {
			// todo: setup true batching on limits
			throw new Error(
				'Attempting to batch update more than 500 records.',
			);
		}

		const batch = writeBatch(this.firestore);
		const activityTasks: Promise<Activity>[] = [];

		const now = Timestamp.now();
		payloads.forEach((payload) => {
			payload.updatedAt = now;

			const docRef = doc(this.collection, payload.id);
			batch.update(docRef, sanitize(payload) as UpdateData<T>);
			if (this.userId.value && options.addActivityLog) {
				activityTasks.push(
					this.logActivity(
						docRef,
						{ type: options.type },
						this.userRef ?? this.userId.value,
					),
				);
			}
		});

		// we need the activity promises to complete AFTER the entity actions occur or
		// a race case could occur and we add notifications to an object that doesn't
		// exist yet
		return batch.commit().then(() => {
			// we don't need to wait for this to complete
			Promise.all(activityTasks);
			this.changed$.next();
			return payloads;
		});
	}

	/**
	 * Batch updates multiple entries at the same time.
	 *
	 * @param payloads The collection of entries to create.
	 */
	batchDelete(payloads: Partial<T & Entity>[]) {
		if (payloads.length > MaxBatch) {
			// todo: setup true batching on limits
			throw new Error(
				'Attempting to batch update more than 500 records.',
			);
		}

		const batch = writeBatch(this.firestore);
		const activityTasks: Promise<Activity>[] = [];

		const now = Timestamp.now();
		payloads.forEach((payload) => {
			const docRef = doc(this.collection, payload.id);
			batch.update(docRef, { deletedAt: now } as UpdateData<T>);
			if (this.userId.value) {
				activityTasks.push(
					this.logActivity(
						docRef,
						{ type: 'delete' },
						this.userRef ?? this.userId.value,
					),
				);
			}
		});

		// we need the activity promises to complete AFTER the entity actions occur or
		// a race case could occur and we add notifications to an object that doesn't
		// exist yet
		return batch.commit().then(() => {
			// we don't need to wait for this to complete
			Promise.all(activityTasks);
			this.changed$.next();
			return payloads;
		});
	}

	/**
	 * Finds a collection's document record by it's id
	 *
	 * @param id The unique id of the document.
	 */
	findById(id: string) {
		return getDoc(doc(this.collection, id)).then((doc) => {
			if (!doc.exists()) throw new Error('Document does not exist');
			return doc;
		});
	}

	/**
	 * Fetches all the records that match the uid
	 *
	 * @param uids The unique identifiers to fetch.
	 */
	async fetchByIdentities(uids: string[] = []) {
		if (uids.length === 0) return [];

		// This is a limitation on Firebase. You can only use the `in` Filter Operation with a max of a 30 length array
		const BATCH_SIZE = 30;
		const totalBatches = Math.ceil(uids.length / BATCH_SIZE);

		const value = [];
		for (let i = 0; i < totalBatches; i++) {
			const batchIds = uids.slice(i * BATCH_SIZE, (i + 1) * BATCH_SIZE);

			const queryDocs = query(
				this.collection,
				where(documentId(), 'in', batchIds),
			);

			const { docs } = await getDocs(queryDocs);
			value.push(...docs.map((doc) => doc.data() as T));
		}
		return value;
	}

	/**
	 * Fetches all the document records in a collection as their raw reference payload. This
	 * may be needed in order to set the references properly on other documents.
	 */
	fetchReferences() {
		return getDocs(query(this.collection, where('deletedAt', '==', null)));
	}

	/**
	 * Fetches all the document records in a collection
	 */
	fetch() {
		return this.fetchReferences().then(({ docs }) =>
			docs.map((doc) => doc.data() as T),
		);
	}

	/**
	 * Fetches the data from the initializing collection and leaves the stream open for the
	 * implementation to determine how to handle it. Useful for needing immediate updating
	 * for new data like alerting users when a new notification comes through
	 */
	fetchStream(options?: { sortBy?: keyof T; sortDir?: SortDir }) {
		const constraints: QueryConstraint[] = [where('deletedAt', '==', null)];

		// only allow strings for the sortBy value
		if (options?.sortBy && typeof options.sortBy === 'string')
			constraints.push(orderBy(options.sortBy, options.sortDir ?? 'asc'));

		return collectionData(query(this.collection, ...constraints));
	}

	/**
	 * Resets the cached items collection.
	 */
	reset() {
		this.items.clear();
	}

	/**
	 * Finds an item located in the cache layer.
	 *
	 * @param id The item id to find in cache
	 */
	findInCacheById(id: string) {
		return this.items.get(id);
	}

	/**
	 * Fetches a collection of items from the cache layer
	 *
	 * @param ids The collection of ids to find
	 */
	fetchFromCache(ids: string[]) {
		return ids.reduce((value, current) => {
			const item = this.findInCacheById(current);
			if (item) value.push(item);
			return value;
		}, [] as T[]);
	}

	/**
	 * Loads the items that have not already been loaded
	 * into the cache layer of the service.
	 *
	 * @param ids The collection of all item ids that need to be available.
	 */
	differentialFetchByIdentities(ids: string[]) {
		ids = ids.filter((id) => !!id);
		const itemIds = [...Array.from(new Set(ids))];

		const diff = itemIds.filter((id) => this.items.has(id));
		return this.fetchByIdentities(diff).then((items) => {
			items.forEach((item) => this.items.set(item.id, item));
			return items;
		});
	}

	/**
	 * Logs a new activity item onto the collection that this service is responsible for.
	 *
	 * @note If a collection has not be determined or initialized then the activity cannot
	 * be logged from an entity<string>.id. You will need to provide the DocumentReference.
	 * Sometimes, it may make more sense to inject the activity service directly into the
	 * implementation and provide a collection manually.
	 *
	 * @param entity either the entity ID or the entity document reference to apply the log to
	 * @param type the type of activity to log
	 * @returns the newly logged activity item
	 */
	logActivity(
		entity: string | DocumentReference<T>,
		activity: Pick<Activity, 'type' | 'data'>,
		creator: string | DocumentReference<User>,
	) {
		const { type, data } = activity;
		if (typeof entity === 'string' && !this.collection)
			throw new Error(
				'Collection has not be initialized on this service',
			);

		// determines the creator of the entity based on the id provided
		// and the current organization's user collection
		let creatorRef: null | DocumentReference<User> = null;
		if (typeof creator === 'string') {
			const userCollection = collection(
				this.firestore,
				`organizations/${this.organizationId.value}/users`,
			) as CollectionReference<User>;
			creatorRef = doc(userCollection, creator);
		} else creatorRef = creator;

		const entityRef =
			typeof entity === 'string' ? doc(this.collection, entity) : entity;
		return this.activityService.create(
			entityRef,
			{ type, data },
			creatorRef,
		);
	}

	/**
	 * Fetches and returns the activity logs tied to the entity that is provided.
	 *
	 * @note If a collection has not been determined or initialized, then the activity cannot be
	 * fetched from an entity<string>.id. You will need to provide the DocumentReference.
	 * Sometimes, it may make more sense to inject the activity service directly into the
	 * implementation and provide a collection manually.
	 *
	 * @param entity either the entity ID or the entity document reference to fetch the logs from.
	 * @returns an array of activity logs tied to this entity
	 */
	fetchActivity(entity: string | DocumentReference<T>) {
		if (typeof entity === 'string' && !this.collection)
			throw new Error(
				'Collection has not be initialized on this service',
			);

		const entityRef =
			typeof entity === 'string' ? doc(this.collection, entity) : entity;
		return this.activityService.fetch(entityRef);
	}
}

type UpdateOptionsBase = {
	addActivityLog: boolean;
	type?: never | ActivityType;
};

interface UpdateOptionsWithLog extends UpdateOptionsBase {
	addActivityLog: true;
	type: ActivityType;
}

interface UpdateOptionsWithoutLog extends UpdateOptionsBase {
	addActivityLog: false;
	type?: never;
}

export type UpdateOptions = UpdateOptionsWithLog | UpdateOptionsWithoutLog;
