import { AsyncPipe, NgIf } from '@angular/common';
import * as i0 from '@angular/core';
import { inject, Component, ChangeDetectionStrategy, Input, HostListener } from '@angular/core';
import { TuiValidationError } from '@taiga-ui/cdk/classes';
import { TuiLet } from '@taiga-ui/cdk/directives/let';
import { tuiIsString } from '@taiga-ui/cdk/utils/miscellaneous';
import { tuiHeightCollapse, tuiFadeIn } from '@taiga-ui/core/animations';
import { TUI_ANIMATIONS_SPEED, TUI_DEFAULT_ERROR_MESSAGE } from '@taiga-ui/core/tokens';
import { tuiToAnimationOptions } from '@taiga-ui/core/utils';
import { PolymorpheusOutlet, PolymorpheusTemplate } from '@taiga-ui/polymorpheus';
const _c0 = () => ({});
function TuiError_ng_container_0_div_1_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtext(1);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const text_r1 = ctx.polymorpheusOutlet;
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1(" ", text_r1, " ");
  }
}
function TuiError_ng_container_0_div_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 2);
    i0.ɵɵtemplate(1, TuiError_ng_container_0_div_1_ng_container_1_Template, 2, 1, "ng-container", 3);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const defaultErrorMessage_r2 = i0.ɵɵnextContext().tuiLet;
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵproperty("@tuiFadeIn", ctx_r2.options)("@tuiHeightCollapse", ctx_r2.options);
    i0.ɵɵadvance();
    i0.ɵɵproperty("polymorpheusOutlet", ctx_r2.error.message || defaultErrorMessage_r2)("polymorpheusOutletContext", ctx_r2.error.context || i0.ɵɵpureFunction0(4, _c0));
  }
}
function TuiError_ng_container_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, TuiError_ng_container_0_div_1_Template, 2, 5, "div", 1);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r2.error && ctx_r2.visible);
  }
}
class TuiError {
  constructor() {
    this.options = tuiToAnimationOptions(inject(TUI_ANIMATIONS_SPEED));
    this.error = null;
    this.visible = true;
    this.defaultErrorMessage$ = inject(TUI_DEFAULT_ERROR_MESSAGE);
  }
  set errorSetter(error) {
    this.error = tuiIsString(error) ? new TuiValidationError(error) : error;
  }
  onAnimation(visible) {
    this.visible = visible;
  }
  static {
    this.ɵfac = function TuiError_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || TuiError)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: TuiError,
      selectors: [["tui-error"]],
      hostBindings: function TuiError_HostBindings(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵlistener("animationcancel.self", function TuiError_animationcancel_self_HostBindingHandler() {
            return ctx.onAnimation(false);
          })("animationstart.self", function TuiError_animationstart_self_HostBindingHandler() {
            return ctx.onAnimation(true);
          });
        }
      },
      inputs: {
        errorSetter: [0, "error", "errorSetter"]
      },
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      decls: 2,
      vars: 3,
      consts: [[4, "tuiLet"], ["automation-id", "tui-error__text", "class", "t-message-text", 4, "ngIf"], ["automation-id", "tui-error__text", 1, "t-message-text"], [4, "polymorpheusOutlet", "polymorpheusOutletContext"]],
      template: function TuiError_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵtemplate(0, TuiError_ng_container_0_Template, 2, 1, "ng-container", 0);
          i0.ɵɵpipe(1, "async");
        }
        if (rf & 2) {
          i0.ɵɵproperty("tuiLet", i0.ɵɵpipeBind1(1, 1, ctx.defaultErrorMessage$));
        }
      },
      dependencies: [AsyncPipe, NgIf, PolymorpheusOutlet, TuiLet],
      styles: ["[_nghost-%COMP%]{display:block;font:var(--tui-font-text-s);color:var(--tui-text-negative);word-wrap:break-word;animation:tuiPresent 1s infinite}.t-message-text[_ngcontent-%COMP%]{margin-top:.25rem;white-space:pre-line}"],
      data: {
        animation: [tuiHeightCollapse, tuiFadeIn]
      },
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiError, [{
    type: Component,
    args: [{
      standalone: true,
      selector: 'tui-error',
      imports: [AsyncPipe, NgIf, PolymorpheusOutlet, PolymorpheusTemplate, TuiLet],
      changeDetection: ChangeDetectionStrategy.OnPush,
      animations: [tuiHeightCollapse, tuiFadeIn],
      template: "<ng-container *tuiLet=\"defaultErrorMessage$ | async as defaultErrorMessage\">\n    <div\n        *ngIf=\"error && visible\"\n        automation-id=\"tui-error__text\"\n        class=\"t-message-text\"\n        [@tuiFadeIn]=\"options\"\n        [@tuiHeightCollapse]=\"options\"\n    >\n        <ng-container *polymorpheusOutlet=\"error.message || defaultErrorMessage as text; context: error.context || {}\">\n            {{ text }}\n        </ng-container>\n    </div>\n</ng-container>\n",
      styles: [":host{display:block;font:var(--tui-font-text-s);color:var(--tui-text-negative);word-wrap:break-word;animation:tuiPresent 1s infinite}.t-message-text{margin-top:.25rem;white-space:pre-line}\n"]
    }]
  }], null, {
    errorSetter: [{
      type: Input,
      args: ['error']
    }],
    onAnimation: [{
      type: HostListener,
      args: ['animationcancel.self', ['false']]
    }, {
      type: HostListener,
      args: ['animationstart.self', ['true']]
    }]
  });
})();

/**
 * Generated bundle index. Do not edit.
 */

export { TuiError };
