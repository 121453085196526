import { Content, DocumentReference } from '../types';

export function createPath(
	content: Content,
	parentPath: DocumentReference<Content>[] | null,
): DocumentReference<Content>[] | null {
	if (!content.parent) return null;

	const path = parentPath ?? [];
	const last = path.slice(-1)[0]; // the parent would be the last one

	const includesParent = last?.id === content.parent.id;
	if (!includesParent) path.push(content.parent);

	return path;
}
