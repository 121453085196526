import { Pipe, PipeTransform } from '@angular/core';
import { Content } from '@context/shared/types/common';

@Pipe({
	standalone: true,
	name: 'backUrl',
})
export class BackUrlPipe implements PipeTransform {
	transform(content: Content) {
		const url = ['/home'];
		if (content.parent) url.push('folder', content.parent.id);
		return url.join('/');
	}
}
