import { inject } from '@angular/core';
import { AbstractControl, Validators } from '@angular/forms';
import { MaxInputLength } from '@context/shared/types/form';
import { TranslocoService } from '@jsverse/transloco';

const PasswordPropertyValidators = [
	'length',
	'special',
	'number',
	'lower',
	'upper',
] as const;
type PasswordPropertyValidator = (typeof PasswordPropertyValidators)[number];

type PasswordValidators<T = string> = {
	[key in PasswordPropertyValidator]: T;
};

const RegExpValidators: PasswordValidators<RegExp> = {
	length: new RegExp(/^.{8,20}$/),
	special: new RegExp('[!-\\/:-@[-`{-~]'),
	number: new RegExp('[0-9]'),
	lower: new RegExp('[a-z]'),
	upper: new RegExp('[A-Z]'),
};

export class FormValidators {
	readonly maxLength = MaxInputLength;

	readonly transloco = inject(TranslocoService);

	getRequiredValidator = (field: AbstractControl, translation: string) =>
		Validators.required(field)
			? { required: this.transloco.translate(translation) }
			: null;

	getEmailValidator = (field: AbstractControl, translation: string) =>
		Validators.email(field)
			? { email: this.transloco.translate(translation) }
			: null;

	getPasswordValidator = (field: AbstractControl) => {
		const length = RegExpValidators.length.test(field.value);
		const special = RegExpValidators.special.test(field.value);
		const lower = RegExpValidators.lower.test(field.value);
		const upper = RegExpValidators.upper.test(field.value);
		const number = RegExpValidators.number.test(field.value);

		const error: Partial<PasswordValidators> = {};
		if (!length)
			error['length'] = this.transloco.translate(
				'auth.password-validator.length',
			);

		if (!lower)
			error['lower'] = this.transloco.translate(
				'auth.password-validator.lower',
			);

		if (!upper)
			error['upper'] = this.transloco.translate(
				'auth.password-validator.upper',
			);

		if (!number)
			error['number'] = this.transloco.translate(
				'auth.password-validator.number',
			);

		if (!special)
			error['special'] = this.transloco.translate(
				'auth.password-validator.special',
			);

		return Object.keys(error).length ? error : null;
	};

	getMatchingValidator = (
		field: AbstractControl,
		match: AbstractControl,
		translation: string,
	) =>
		field.value !== match.value
			? { match: this.transloco.translate(translation) }
			: null;
}
