import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';
import { TuiButton, TuiIcon, TuiLoader, TuiNotification } from '@taiga-ui/core';
import { TuiHeader } from '@taiga-ui/layout';
import { ImageCroppedEvent, ImageCropperComponent } from 'ngx-image-cropper';

@Component({
	standalone: true,
	imports: [
		ImageCropperComponent,
		TuiButton,
		TuiIcon,
		TranslocoModule,
		TuiLoader,
		TuiNotification,
		TuiHeader,
	],
	selector: 'ctx-image-cropper-dialog',
	styleUrl: 'image-cropper-dialog.component.scss',
	templateUrl: 'image-cropper-dialog.component.html',
})
export class ImageCropperDialogComponent implements OnInit {
	@Input()
	imageFile: File | null = null;

	@Output() dismiss = new EventEmitter<File | null>();

	croppedImage: File | null = null;

	cropperLoading = true;
	imageLoading = true;
	loading = true;

	/**
	 * `true` if there was an error loading the image cropper
	 */
	error = false;

	ngOnInit() {
		if (!this.imageFile) this.dismiss.emit(null);
	}

	onImageCropped(event: ImageCroppedEvent) {
		if (!event.blob || !this.imageFile) return;
		this.croppedImage = new File([event.blob], this.imageFile.name, {
			type: this.imageFile.type,
		});
	}

	onImageLoaded() {
		this.imageLoading = false;
		this.determineLoadState();
	}

	onCropperReady() {
		this.cropperLoading = false;
		this.determineLoadState();
	}

	onLoadImageFailed() {
		this.error = true;
		this.imageLoading = false;
		this.cropperLoading = false;
		this.determineLoadState();
	}

	determineLoadState() {
		this.loading = this.cropperLoading || this.imageLoading;
	}

	onSave() {
		this.dismiss.emit(this.croppedImage);
	}

	onDismiss() {
		this.dismiss.emit();
	}
}
