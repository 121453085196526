import * as i0 from '@angular/core';
import { Pipe } from '@angular/core';
import { tuiInjectElement } from '@taiga-ui/cdk/utils/dom';
import { fromEvent, map, startWith } from 'rxjs';
class TuiFallbackSrcPipe {
  constructor() {
    this.el = tuiInjectElement();
  }
  transform(src, fallback) {
    return fromEvent(this.el, 'error', {
      capture: true
    }).pipe(map(() => fallback), startWith(src));
  }
  static {
    this.ɵfac = function TuiFallbackSrcPipe_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || TuiFallbackSrcPipe)();
    };
  }
  static {
    this.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
      name: "tuiFallbackSrc",
      type: TuiFallbackSrcPipe,
      pure: true,
      standalone: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiFallbackSrcPipe, [{
    type: Pipe,
    args: [{
      standalone: true,
      name: 'tuiFallbackSrc'
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { TuiFallbackSrcPipe };
