import { AsyncPipe, NgTemplateOutlet } from '@angular/common';
import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	Output,
} from '@angular/core';
import {
	FormControl,
	FormGroup,
	FormsModule,
	ReactiveFormsModule,
} from '@angular/forms';
import { RouterLink } from '@angular/router';
import { FormValidators } from '@context/frontend/form';
import { getFirebaseErrorKey } from '@context/shared/utils';
import { TranslocoModule } from '@jsverse/transloco';
import {
	TuiButton,
	TuiError,
	TuiIcon,
	TuiLabel,
	TuiLink,
	TuiNotification,
	TuiTextfield,
} from '@taiga-ui/core';
import { TuiButtonLoading, TuiFieldErrorPipe } from '@taiga-ui/kit';
import { TuiHeader } from '@taiga-ui/layout';
import {
	TuiInputPasswordModule,
	TuiTextfieldControllerModule,
} from '@taiga-ui/legacy';
import { FirebaseError } from 'firebase/app';
import { AuthService } from '../../services';

@Component({
	standalone: true,
	imports: [
		TranslocoModule,
		TuiButton,
		ReactiveFormsModule,
		FormsModule,
		TuiTextfield,
		TuiLabel,
		TuiLink,
		RouterLink,
		TuiButtonLoading,
		TuiInputPasswordModule,
		TuiTextfieldControllerModule,
		TuiError,
		TuiFieldErrorPipe,
		AsyncPipe,
		NgTemplateOutlet,
		TuiNotification,
		TuiHeader,
		TuiIcon,
	],
	selector: 'ctx-authentication-dialog',
	templateUrl: 'authentication-dialog.component.html',
	styleUrl: 'authentication-dialog.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AuthenticationDialogComponent extends FormValidators {
	@Input() message: string | null = null;

	@Output() dismiss = new EventEmitter<boolean>();

	busy = false;

	form: null | FormGroup = null;

	/**
	 * the current error being displayed
	 */
	error: string | null = null;

	constructor(private readonly authService: AuthService) {
		super();
		this.setupForm();
	}

	setupForm() {
		this.form = new FormGroup({
			email: new FormControl(null, [
				(field) =>
					this.getEmailValidator(field, 'form.error.email.valid'),
				(field) =>
					this.getRequiredValidator(
						field,
						'form.error.email.required',
					),
			]),
			password: new FormControl(null, (field) =>
				this.getRequiredValidator(
					field,
					'form.error.password.required',
				),
			),
		});
	}

	onDismiss() {
		this.dismiss.emit(false);
	}

	onSubmit() {
		this.form?.markAllAsTouched();
		if (this.busy || !this.form || this.form?.invalid) return;

		this.form.disable();
		this.busy = true;

		const { email, password } = this.form.getRawValue();
		this.authService
			.reauthenticateWithEmailAndPassword(email, password)
			.then(() => this.dismiss.emit(true))
			.catch((error: FirebaseError) => {
				this.error = getFirebaseErrorKey(error.code);
				this.busy = false;
				this.form?.enable();
			});
	}
}
