import { __decorate } from 'tslib';
import * as i0 from '@angular/core';
import { signal, inject, DestroyRef, ChangeDetectorRef, computed, Directive, Input, INJECTOR, ViewContainerRef, ViewChild, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NgControl, NgModel } from '@angular/forms';
import { EMPTY_FUNCTION } from '@taiga-ui/cdk/constants';
import { TUI_FALLBACK_VALUE } from '@taiga-ui/cdk/tokens';
import { tuiIsPresent, tuiPure, tuiProvide } from '@taiga-ui/cdk/utils';
import { Subject, delay, startWith, map, filter, distinctUntilChanged, switchMap, merge } from 'rxjs';
const _c0 = ["viewContainer"];
class TuiValueTransformer {}

/**
 * Basic ControlValueAccessor class to build form components upon
 */
class TuiControl {
  constructor() {
    this.refresh$ = new Subject();
    this.pseudoInvalid = signal(null);
    this.internal = signal(inject(TUI_FALLBACK_VALUE, {
      self: true,
      optional: true
    }));
    this.control = inject(NgControl, {
      self: true
    });
    this.destroyRef = inject(DestroyRef);
    this.cdr = inject(ChangeDetectorRef);
    this.transformer = inject(TuiValueTransformer, {
      optional: true
    });
    this.value = computed(() => this.internal());
    this.readOnly = signal(false);
    this.touched = signal(false);
    this.status = signal(undefined);
    this.disabled = computed(() => this.status() === 'DISABLED');
    this.interactive = computed(() => !this.disabled() && !this.readOnly());
    this.invalid = computed(() => this.pseudoInvalid() !== null ? !!this.pseudoInvalid() && this.interactive() : this.interactive() && this.touched() && this.status() === 'INVALID');
    this.mode = computed(() =>
    // eslint-disable-next-line no-nested-ternary
    this.readOnly() ? 'readonly' : this.invalid() ? 'invalid' : 'valid');
    this.onTouched = EMPTY_FUNCTION;
    this.onChange = EMPTY_FUNCTION;
    this.control.valueAccessor = this;
    this.refresh$.pipe(delay(0), startWith(null), map(() => this.control.control), filter(tuiIsPresent), distinctUntilChanged(), switchMap(control => merge(control.valueChanges, control.statusChanges)), takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      this.update();
    });
  }
  set readOnlySetter(readOnly) {
    this.readOnly.set(readOnly);
  }
  set invalidSetter(invalid) {
    this.pseudoInvalid.set(invalid);
  }
  registerOnChange(onChange) {
    this.refresh$.next();
    this.onChange = value => {
      if (value === this.internal()) {
        return;
      }
      onChange(this.toControlValue(value));
      this.internal.set(value);
      this.update();
    };
  }
  registerOnTouched(onTouched) {
    this.onTouched = () => {
      onTouched();
      this.update();
    };
  }
  setDisabledState() {
    this.update();
  }
  writeValue(value) {
    // TODO: https://github.com/angular/angular/issues/14988
    const safe = this.control instanceof NgModel ? this.control.model : value;
    this.internal.set(this.fromControlValue(safe));
    this.update();
  }
  fromControlValue(value) {
    return this.transformer ? this.transformer.fromControlValue(value) : value;
  }
  toControlValue(value) {
    return this.transformer ? this.transformer.toControlValue(value) : value;
  }
  update() {
    this.status.set(this.control.control?.status);
    this.touched.set(!!this.control.control?.touched);
    this.cdr.markForCheck();
  }
  static {
    this.ɵfac = function TuiControl_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || TuiControl)();
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: TuiControl,
      inputs: {
        readOnlySetter: [0, "readOnly", "readOnlySetter"],
        invalidSetter: [0, "invalid", "invalidSetter"]
      }
    });
  }
}
__decorate([tuiPure], TuiControl.prototype, "fromControlValue", null);
__decorate([tuiPure], TuiControl.prototype, "toControlValue", null);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiControl, [{
    type: Directive
  }], function () {
    return [];
  }, {
    readOnlySetter: [{
      type: Input,
      args: ['readOnly']
    }],
    invalidSetter: [{
      type: Input,
      args: ['invalid']
    }],
    fromControlValue: [],
    toControlValue: []
  });
})();
function tuiAsControl(control) {
  return tuiProvide(TuiControl, control);
}

/// <reference types="@taiga-ui/tsconfig/ng-dev-mode" />
/**
 * Abstract class for host element for dynamically created portals.
 */
class TuiPortals {
  constructor() {
    this.injector = inject(INJECTOR);
    this.nothing = inject(TuiPortalService).attach(this);
  }
  addComponentChild(component) {
    const injector = component.createInjector(this.injector);
    const ref = this.vcr.createComponent(component.component, {
      injector
    });
    ref.changeDetectorRef.detectChanges();
    return ref;
  }
  addTemplateChild(templateRef, context) {
    return this.vcr.createEmbeddedView(templateRef, context);
  }
  static {
    this.ɵfac = function TuiPortals_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || TuiPortals)();
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: TuiPortals,
      viewQuery: function TuiPortals_Query(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵviewQuery(_c0, 5, ViewContainerRef);
        }
        if (rf & 2) {
          let _t;
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.vcr = _t.first);
        }
      }
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiPortals, [{
    type: Directive
  }], null, {
    vcr: [{
      type: ViewChild,
      args: ['viewContainer', {
        read: ViewContainerRef
      }]
    }]
  });
})();
/**
 * Abstract service for displaying portals
 */
class TuiPortalService {
  attach(host) {
    this.host = host;
  }
  add(component) {
    return this.safeHost.addComponentChild(component);
  }
  remove({
    hostView
  }) {
    if (!hostView.destroyed) {
      hostView.destroy();
    }
  }
  addTemplate(templateRef, context) {
    return this.safeHost.addTemplateChild(templateRef, context);
  }
  removeTemplate(viewRef) {
    if (!viewRef.destroyed) {
      viewRef.destroy();
    }
  }
  get safeHost() {
    if (!this.host) {
      throw new TuiNoHostException();
    }
    return this.host;
  }
  static {
    this.ɵfac = function TuiPortalService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || TuiPortalService)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: TuiPortalService,
      factory: TuiPortalService.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiPortalService, [{
    type: Injectable
  }], null, null);
})();
function tuiAsPortal(portal) {
  return tuiProvide(TuiPortalService, portal);
}
class TuiNoHostException extends Error {
  constructor() {
    super(ngDevMode ? 'Portals cannot be used without TuiPortalHostComponent' : '');
  }
}
class TuiValidationError {
  constructor(message, context = {}) {
    this.message = message;
    this.context = context;
  }
}

/**
 * Generated bundle index. Do not edit.
 */

export { TuiControl, TuiNoHostException, TuiPortalService, TuiPortals, TuiValidationError, TuiValueTransformer, tuiAsControl, tuiAsPortal };
