<header tuiHeader>
	<h3 class="margin-0" tuiTitle>{{ 'user.manage-user' | transloco }}</h3>

	<div tuiAccessories>
		<button tuiIconButton size="m" (click)="onDismiss()" appearance="ghost">
			<tui-icon icon="@tui.x"></tui-icon>
		</button>
	</div>
</header>

<div class="dialog-menu__content">
	<button
		tuiButton
		size="l"
		class="menu-button"
		iconStart="@tui.pencil-line"
		iconEnd="@tui.chevron-right"
		(click)="onEdit()"
	>
		<span>{{ 'user.edit-user-profile' | transloco }}</span>
	</button>

	<button
		tuiButton
		size="l"
		class="menu-button"
		iconStart="@tui.trash"
		(click)="onRemove()"
	>
		<span>{{ 'user.delete-user' | transloco }}</span>
	</button>
</div>
