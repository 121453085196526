import * as i0 from '@angular/core';
import { Directive, Input, HostBinding, NgModule } from '@angular/core';

/**
 * @deprecated: drop in v5.0
 */
class TuiWrapperDirective {
  constructor() {
    this.disabled = false;
    this.readOnly = false;
    this.hover = null;
    this.active = null;
    this.focus = false;
    this.invalid = false;
    this.appearance = '';
  }
  get computedInvalid() {
    return !this.disabled && !this.readOnly && this.invalid;
  }
  get computedFocused() {
    return this.focus && !this.disabled;
  }
  get interactiveState() {
    if (this.disabled) {
      return 'disabled';
    }
    if (this.readOnly) {
      return 'readonly';
    }
    if (this.active) {
      return 'active';
    }
    if (this.hover) {
      return 'hover';
    }
    return null;
  }
  get noHover() {
    return this.readOnly || this.hover === false;
  }
  get noActive() {
    return this.readOnly || this.active === false;
  }
  static {
    this.ɵfac = function TuiWrapperDirective_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || TuiWrapperDirective)();
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: TuiWrapperDirective,
      selectors: [["", "tuiWrapper", ""]],
      hostVars: 10,
      hostBindings: function TuiWrapperDirective_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵattribute("data-appearance", ctx.appearance)("data-state", ctx.interactiveState);
          i0.ɵɵclassProp("_invalid", ctx.computedInvalid)("_focused", ctx.computedFocused)("_no-hover", ctx.noHover)("_no-active", ctx.noActive);
        }
      },
      inputs: {
        disabled: "disabled",
        readOnly: "readOnly",
        hover: "hover",
        active: "active",
        focus: "focus",
        invalid: "invalid",
        appearance: "appearance"
      }
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiWrapperDirective, [{
    type: Directive,
    args: [{
      selector: '[tuiWrapper]'
    }]
  }], null, {
    disabled: [{
      type: Input
    }],
    readOnly: [{
      type: Input
    }],
    hover: [{
      type: Input
    }],
    active: [{
      type: Input
    }],
    focus: [{
      type: Input
    }],
    invalid: [{
      type: Input
    }],
    appearance: [{
      type: Input
    }, {
      type: HostBinding,
      args: ['attr.data-appearance']
    }],
    computedInvalid: [{
      type: HostBinding,
      args: ['class._invalid']
    }],
    computedFocused: [{
      type: HostBinding,
      args: ['class._focused']
    }],
    interactiveState: [{
      type: HostBinding,
      args: ['attr.data-state']
    }],
    noHover: [{
      type: HostBinding,
      args: ['class._no-hover']
    }],
    noActive: [{
      type: HostBinding,
      args: ['class._no-active']
    }]
  });
})();

/**
 * @deprecated: drop in v5.0
 */
class TuiWrapperModule {
  static {
    this.ɵfac = function TuiWrapperModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || TuiWrapperModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: TuiWrapperModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiWrapperModule, [{
    type: NgModule,
    args: [{
      declarations: [TuiWrapperDirective],
      exports: [TuiWrapperDirective]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { TuiWrapperDirective, TuiWrapperModule };
