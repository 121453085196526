import { Pipe, PipeTransform } from '@angular/core';
import { hasInheritedRolePermission, Role } from '@context/shared/types/user';
import { AuthService } from '../services';

@Pipe({
	name: 'roleAccess',
	standalone: true,
})
export class RoleAccessPipe implements PipeTransform {
	constructor(private readonly authService: AuthService) {}

	transform(role: Role) {
		if (!this.authService.user?.role) return false;
		return hasInheritedRolePermission(this.authService.user?.role, role);
	}
}
