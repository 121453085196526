import { Injectable } from '@angular/core';
import {
	collection,
	doc,
	DocumentReference,
	getDocs,
	setDoc,
} from '@angular/fire/firestore';
import { Activity, ActivityMap } from '@context/shared/types/activity';
import { Entity } from '@context/shared/types/common';
import { User } from '@context/shared/types/user';
import { sanitize } from '@context/shared/utils';
import { Timestamp } from 'firebase/firestore';
import { v7 } from 'uuid';

@Injectable({ providedIn: 'root' })
export class ActivityService {
	private static CollectionID = 'activity';

	create(
		entity: DocumentReference<Entity>,
		activity: Pick<Activity, 'type' | 'data'>,
		creator: DocumentReference<User>,
	): Promise<Activity> {
		const { type, data } = activity;
		const now = Timestamp.now();
		const payload: Activity = {
			...ActivityMap[type],
			event: 'user',
			// we assume user at this level since it's on the client side
			// server side will typically consist of system level events
			user: creator,
			createdAt: now,
			updatedAt: now,
			data,
			id: v7(),
		};

		// the activity collection tied to the entity
		const activityCollection = collection(
			entity,
			ActivityService.CollectionID,
		);

		return setDoc(
			doc(activityCollection, payload.id),
			sanitize(payload),
		).then(() => payload);
	}

	fetch(entity: DocumentReference<Entity>) {
		// the activity collection tied to the entity
		const activityCollection = collection(
			entity,
			ActivityService.CollectionID,
		);

		return getDocs(
			collection(activityCollection, ActivityService.CollectionID),
		).then((snapshot) =>
			snapshot.docs.map((doc) => doc.data() as Activity),
		);
	}
}
