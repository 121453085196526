import * as i0 from '@angular/core';
import { EventEmitter, Directive, Input, HostBinding, Output, inject, DestroyRef, ChangeDetectorRef, TemplateRef, ViewChild } from '@angular/core';
import { TuiIdService } from '@taiga-ui/cdk/services';
import { tuiInjectElement } from '@taiga-ui/cdk/utils/dom';
import { TuiDataListDirective } from '@taiga-ui/core/components/data-list';
import { TUI_ITEMS_HANDLERS } from '@taiga-ui/kit/tokens';
import { TUI_TEXTFIELD_HOST } from '@taiga-ui/legacy/tokens';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NgControl, NgModel } from '@angular/forms';
import { TuiValueTransformer } from '@taiga-ui/cdk/classes';
import { EMPTY_FUNCTION } from '@taiga-ui/cdk/constants';
import { tuiIsPresent, tuiProvide } from '@taiga-ui/cdk/utils/miscellaneous';
import { Subject, delay, startWith, map, filter, distinctUntilChanged, switchMap, merge } from 'rxjs';
const TUI = 'tui_interactive_';
/**
 * @deprecated: drop in v5.0
 */
class AbstractTuiInteractive {
  static {
    this.autoId = 0;
  }
  constructor() {
    this.focusVisible = false;
    this.pseudoHover = null;
    this.pseudoActive = null;
    this.pseudoFocus = null;
    /**
     * Determines if component is focusable with keyboard.
     */
    this.focusable = true;
    this.nativeId = '';
    /**
     * Emits 'true' on focus and 'false' on blur.
     */
    this.focusedChange = new EventEmitter();
    this.focusVisibleChange = new EventEmitter();
    this.autoIdString = `${TUI}${AbstractTuiInteractive.autoId++}${Date.now()}`;
  }
  get computedDisabled() {
    return this.disabled;
  }
  get computedFocused() {
    return !this.computedDisabled && (this.pseudoFocus ?? this.focused);
  }
  get computedFocusVisible() {
    return !this.computedDisabled && (this.pseudoFocus ?? this.focusVisible);
  }
  get computedFocusable() {
    return !this.computedDisabled && (this.focusable || this.focused);
  }
  // TODO: 3.0 Consider removing since native input is exposed
  get id() {
    return this.nativeId || this.autoIdString;
  }
  updateFocused(focused) {
    this.focusedChange.emit(focused);
  }
  updateFocusVisible(focusVisible) {
    if (this.focusVisible === focusVisible) {
      return;
    }
    this.focusVisible = focusVisible;
    this.focusVisibleChange.emit(focusVisible);
  }
  static {
    this.ɵfac = function AbstractTuiInteractive_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || AbstractTuiInteractive)();
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: AbstractTuiInteractive,
      hostVars: 7,
      hostBindings: function AbstractTuiInteractive_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵattribute("data-focused", ctx.pseudoFocus);
          i0.ɵɵclassProp("_disabled", ctx.computedDisabled)("_focused", ctx.computedFocused)("_focus-visible", ctx.computedFocusVisible);
        }
      },
      inputs: {
        pseudoHover: "pseudoHover",
        pseudoActive: "pseudoActive",
        pseudoFocus: "pseudoFocus",
        focusable: "focusable",
        nativeId: "nativeId"
      },
      outputs: {
        focusedChange: "focusedChange",
        focusVisibleChange: "focusVisibleChange"
      }
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AbstractTuiInteractive, [{
    type: Directive
  }], function () {
    return [];
  }, {
    pseudoHover: [{
      type: Input
    }],
    pseudoActive: [{
      type: Input
    }],
    pseudoFocus: [{
      type: Input
    }, {
      type: HostBinding,
      args: ['attr.data-focused']
    }],
    focusable: [{
      type: Input
    }],
    nativeId: [{
      type: Input
    }],
    focusedChange: [{
      type: Output
    }],
    focusVisibleChange: [{
      type: Output
    }],
    computedDisabled: [{
      type: HostBinding,
      args: ['class._disabled']
    }],
    computedFocused: [{
      type: HostBinding,
      args: ['class._focused']
    }],
    computedFocusVisible: [{
      type: HostBinding,
      args: ['class._focus-visible']
    }]
  });
})();

/// <reference types="@taiga-ui/tsconfig/ng-dev-mode" />
/**
 * @deprecated: drop in v5.0
 * Basic ControlValueAccessor class to build form components upon
 */
class AbstractTuiControl extends AbstractTuiInteractive {
  constructor() {
    super();
    this.ngControl = inject(NgControl, {
      optional: true
    });
    this.refresh$ = new Subject();
    this.onTouched = EMPTY_FUNCTION;
    this.onChange = EMPTY_FUNCTION;
    this.fallbackValue = this.getFallbackValue();
    this.destroyRef = inject(DestroyRef);
    this.cdr = inject(ChangeDetectorRef);
    this.valueTransformer = inject(TuiValueTransformer, {
      optional: true
    });
    this.readOnly = false;
    this.pseudoInvalid = null;
    if (ngDevMode && this.ngControl === null) {
      console.assert(false, `NgControl not injected in ${this.constructor.name}!\n`, 'Use [(ngModel)] or [formControl] or formControlName for correct work.');
    }
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }
  get computedInvalid() {
    return this.interactive && (this.pseudoInvalid !== null ? this.pseudoInvalid : this.touched && this.invalid);
  }
  get value() {
    return this.previousInternalValue ?? this.fallbackValue;
  }
  set value(value) {
    this.updateValue(value);
  }
  get safeCurrentValue() {
    return this.rawValue ?? this.fallbackValue;
  }
  get invalid() {
    return this.safeNgControlData(({
      invalid
    }) => invalid, false);
  }
  get valid() {
    return this.safeNgControlData(({
      valid
    }) => valid, false);
  }
  get touched() {
    return this.safeNgControlData(({
      touched
    }) => touched, false);
  }
  get disabled() {
    return this.safeNgControlData(({
      disabled
    }) => disabled, false);
  }
  get interactive() {
    return !this.readOnly && !this.computedDisabled;
  }
  get control() {
    return this.safeNgControlData(({
      control
    }) => control, null);
  }
  get computedName() {
    return this.controlName?.toString() ?? null;
  }
  get controlName() {
    return this.ngControl?.name?.toString() ?? null;
  }
  ngOnInit() {
    this.refresh$.pipe(delay(0), startWith(null), map(() => this.ngControl?.control), filter(tuiIsPresent), distinctUntilChanged(), switchMap(control => merge(control.valueChanges, control.statusChanges)), takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      this.refreshLocalValue(this.safeCurrentValue);
    });
  }
  checkControlUpdate() {
    this.cdr.markForCheck();
  }
  registerOnChange(onChange) {
    this.onChange = componentValue => {
      onChange(this.toControlValue(componentValue));
    };
    this.refresh$.next();
  }
  registerOnTouched(onTouched) {
    this.onTouched = onTouched;
  }
  setDisabledState() {
    this.checkControlUpdate();
  }
  writeValue(value) {
    const controlValue = this.ngControl instanceof NgModel && this.previousInternalValue === undefined ? this.ngControl.model : value;
    this.refreshLocalValue(this.fromControlValue(controlValue));
  }
  updateFocused(focused) {
    if (!focused) {
      this.controlMarkAsTouched();
    }
    super.updateFocused(focused);
  }
  /**
   * @deprecated use `value` setter
   */
  updateValue(value) {
    if (this.disabled || this.valueIdenticalComparator(this.value, value)) {
      return;
    }
    this.previousInternalValue = value;
    this.controlSetValue(value);
  }
  valueIdenticalComparator(oldValue, newValue) {
    return oldValue === newValue;
  }
  get rawValue() {
    const {
      ngControl
    } = this;
    if (ngControl === null) {
      return undefined;
    }
    const controlValue = ngControl instanceof NgModel && this.previousInternalValue === undefined ? ngControl.viewModel : ngControl.value;
    return this.fromControlValue(controlValue);
  }
  safeNgControlData(extractor, defaultFieldValue) {
    return (this.ngControl && extractor(this.ngControl)) ?? defaultFieldValue;
  }
  controlMarkAsTouched() {
    this.onTouched();
    this.checkControlUpdate();
  }
  controlSetValue(value) {
    this.onChange(value);
    this.checkControlUpdate();
  }
  refreshLocalValue(value) {
    this.previousInternalValue = value;
    this.checkControlUpdate();
  }
  fromControlValue(controlValue) {
    return this.valueTransformer ? this.valueTransformer.fromControlValue(controlValue) : controlValue;
  }
  toControlValue(componentValue) {
    return this.valueTransformer ? this.valueTransformer.toControlValue(componentValue) : componentValue;
  }
  static {
    this.ɵfac = function AbstractTuiControl_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || AbstractTuiControl)();
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: AbstractTuiControl,
      hostVars: 4,
      hostBindings: function AbstractTuiControl_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵclassProp("_readonly", ctx.readOnly)("_invalid", ctx.computedInvalid);
        }
      },
      inputs: {
        readOnly: "readOnly",
        pseudoInvalid: "pseudoInvalid"
      },
      features: [i0.ɵɵInheritDefinitionFeature]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AbstractTuiControl, [{
    type: Directive
  }], function () {
    return [];
  }, {
    readOnly: [{
      type: Input
    }, {
      type: HostBinding,
      args: ['class._readonly']
    }],
    pseudoInvalid: [{
      type: Input
    }],
    computedInvalid: [{
      type: HostBinding,
      args: ['class._invalid']
    }]
  });
})();
function tuiAsControl(control) {
  return tuiProvide(AbstractTuiControl, control);
}

/**
 * @deprecated: drop in v5.0
 */
class AbstractTuiNativeSelect {
  constructor() {
    this.idService = inject(TuiIdService);
    this.datalist = null;
    this.el = tuiInjectElement();
    this.host = inject(TUI_TEXTFIELD_HOST);
    this.control = inject(AbstractTuiControl);
    this.itemsHandlers = inject(TUI_ITEMS_HANDLERS);
    this.disabledItemHandler = null;
    this.placeholder = '';
  }
  get id() {
    return this.el.id || this.idService.generate();
  }
  get emptyOption() {
    return !!this.placeholder && !this.control.value;
  }
  static {
    this.ɵfac = function AbstractTuiNativeSelect_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || AbstractTuiNativeSelect)();
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: AbstractTuiNativeSelect,
      viewQuery: function AbstractTuiNativeSelect_Query(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵviewQuery(TuiDataListDirective, 7, TemplateRef);
        }
        if (rf & 2) {
          let _t;
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.datalist = _t.first);
        }
      },
      hostVars: 1,
      hostBindings: function AbstractTuiNativeSelect_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵhostProperty("id", ctx.id);
        }
      },
      inputs: {
        disabledItemHandler: "disabledItemHandler",
        placeholder: "placeholder"
      }
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AbstractTuiNativeSelect, [{
    type: Directive
  }], null, {
    datalist: [{
      type: ViewChild,
      args: [TuiDataListDirective, {
        read: TemplateRef,
        static: true
      }]
    }],
    disabledItemHandler: [{
      type: Input
    }],
    placeholder: [{
      type: Input
    }],
    id: [{
      type: HostBinding,
      args: ['id']
    }]
  });
})();

/**
 * @deprecated: drop in v5.0
 */
class AbstractTuiTextfieldHost {
  constructor() {
    this.host = inject(AbstractTuiControl, {
      optional: true
    });
  }
  get readOnly() {
    return this.host.readOnly;
  }
  get disabled() {
    return this.host.computedDisabled;
  }
  get invalid() {
    return this.host.computedInvalid;
  }
  get focusable() {
    return this.host.computedFocusable;
  }
  get inputMode() {
    return 'text';
  }
  get value() {
    return this.host.value?.toString() || '';
  }
  process(_input) {}
  static {
    this.ɵfac = function AbstractTuiTextfieldHost_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || AbstractTuiTextfieldHost)();
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: AbstractTuiTextfieldHost
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AbstractTuiTextfieldHost, [{
    type: Directive
  }], null, null);
})();
class AbstractTuiController {
  constructor() {
    this.change$ = new Subject();
  }
  ngOnChanges() {
    this.change$.next();
  }
  static {
    this.ɵfac = function AbstractTuiController_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || AbstractTuiController)();
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: AbstractTuiController,
      features: [i0.ɵɵNgOnChangesFeature]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AbstractTuiController, [{
    type: Directive
  }], null, null);
})();

/**
 * @deprecated: drop in v5.0
 */
class AbstractTuiMultipleControl extends AbstractTuiControl {
  clear() {
    this.value = [];
  }
  getFallbackValue() {
    return [];
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵAbstractTuiMultipleControl_BaseFactory;
      return function AbstractTuiMultipleControl_Factory(__ngFactoryType__) {
        return (ɵAbstractTuiMultipleControl_BaseFactory || (ɵAbstractTuiMultipleControl_BaseFactory = i0.ɵɵgetInheritedFactory(AbstractTuiMultipleControl)))(__ngFactoryType__ || AbstractTuiMultipleControl);
      };
    })();
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: AbstractTuiMultipleControl,
      features: [i0.ɵɵInheritDefinitionFeature]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AbstractTuiMultipleControl, [{
    type: Directive
  }], null, null);
})();

/**
 * @deprecated: drop in v5.0
 */
class TuiNamedDay {
  constructor(day, name, displayDay = day) {
    this.day = day;
    this.name = name;
    this.displayDay = displayDay;
  }
  toString() {
    return this.name;
  }
}

/**
 * @deprecated: drop in v5.0
 */
class AbstractTuiNullableControl extends AbstractTuiControl {
  getFallbackValue() {
    return null;
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵAbstractTuiNullableControl_BaseFactory;
      return function AbstractTuiNullableControl_Factory(__ngFactoryType__) {
        return (ɵAbstractTuiNullableControl_BaseFactory || (ɵAbstractTuiNullableControl_BaseFactory = i0.ɵɵgetInheritedFactory(AbstractTuiNullableControl)))(__ngFactoryType__ || AbstractTuiNullableControl);
      };
    })();
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: AbstractTuiNullableControl,
      features: [i0.ɵɵInheritDefinitionFeature]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AbstractTuiNullableControl, [{
    type: Directive
  }], null, null);
})();

/**
 * @deprecated: drop in v5.0
 * Wrapper around an item to add `toString()` method
 */
class TuiStringifiableItem {
  constructor(item, stringify) {
    this.item = item;
    this.stringify = stringify;
  }
  toString() {
    return this.stringify(this.item);
  }
}

/**
 * Generated bundle index. Do not edit.
 */

export { AbstractTuiControl, AbstractTuiController, AbstractTuiInteractive, AbstractTuiMultipleControl, AbstractTuiNativeSelect, AbstractTuiNullableControl, AbstractTuiTextfieldHost, TuiNamedDay, TuiStringifiableItem, tuiAsControl };
