import { DocumentReference, Timestamp } from '@context/shared/types/common';
import { LanguageCode } from '@context/shared/types/translate';
import { InviteUserDto } from '../models';
import { AuthUser, Role, User, UserStatus } from '../types';

/**
 * Users can control the roles included in this array
 */
// const ExternalControl: Role[] = ['external'];
const ExternalControl: Role[] = [];

/**
 * Users can control the roles included in this array
 */
const UserControl: Role[] = [...ExternalControl, 'user'];
/**
 * Admins can control the roles included in this array
 */
const AdminControl: Role[] = [...UserControl, 'admin'];
/**
 * Owners can control the roles included in this array
 */
const OwnerControl: Role[] = [...AdminControl, 'owner'];
/**
 * Supers can control the roles included in this array
 */
const SuperControl: Role[] = [...OwnerControl, 'super'];

/**
 * Determines if the role provided has inherited role position of the `other` role
 * in question. E.g an admin should have all the same permissions that a user has.
 *
 * @param role the role to check with
 * @param other the role to check against
 * @returns boolean value depicting if the provided role has inherited permissions
 */
export function hasInheritedRolePermission(role: Role, other: Role) {
	switch (role) {
		case 'user':
			return UserControl.includes(other);
		case 'admin':
			return AdminControl.includes(other);
		case 'owner':
			return OwnerControl.includes(other);
		case 'super':
			return SuperControl.includes(other);
		// case 'external':
		// 	return ExternalControl.includes(other);
		default: // if no role is defined or supported, no permission is granted
			return false;
	}
}

/**
 * Determines if the currently authenticated user can perform editing
 * actions on the corresponding user
 * @param authUser The currently authenticated user
 * @param user The user to determine the editing control against
 */
export function canEditUser(authUser: User, user: User) {
	// security for malformed data
	if (user.role == null) user.role = 'user';

	// You cannot edit yourself unless on profile page
	if (authUser.id === user.id) return false;

	switch (authUser.role) {
		case 'admin':
			return AdminControl.includes(user.role);
		case 'owner':
			return OwnerControl.includes(user.role);
		case 'super':
			return SuperControl.includes(user.role);
		default:
			return false;
	}
}

export function createUser(
	payload: InviteUserDto,
	options: {
		createdBy: DocumentReference<User>;
		authUser: DocumentReference<AuthUser>;
		createdAt: Timestamp;
	},
): Omit<User, 'emailVerified'> {
	const { authUser, createdAt, createdBy } = options;
	return {
		id: '', // should be updated after adding
		authUser: authUser ?? null,
		createdBy: createdBy ?? null,
		givenName: payload.givenName ?? null,
		familyName: payload.familyName ?? null,
		role: payload.role,
		email: payload.email,
		thumbnailUrl: null,
		notifications: { email: true },
		lang: LanguageCode.AUTO,
		// @todo rethink on how to default this when more translations are added
		langLastUsed: LanguageCode.EN,
		createdAt: createdAt,
		updatedAt: createdAt,
		deletedAt: null,
		rejectedAt: null,
		acceptedAt: null,
		disabledAt: null,
	};
}

/**
 * Determines what a users current status in the system is
 *
 * @param user the user to determine the status of
 * @returns the users current status in the organization
 */
export function getUserStatus(user: User): UserStatus {
	if (user.deletedAt) return 'deleted';
	else if (user.disabledAt) return 'disabled';
	else if (user.rejectedAt) return 'rejected';
	else if (user.acceptedAt) return 'enabled';
	else return 'pending';
}

export const UserStatusMap: {
	[key in UserStatus]: {
		value: string;
		appearance: string;
		text: string;
		icon: string;
	};
} = {
	pending: {
		value: 'pending',
		icon: '@tui.timer',
		appearance: 'warning',
		text: 'user.status.pending',
	},
	deleted: {
		value: 'deleted',
		icon: '@tui.circle-x',
		appearance: 'error',
		text: 'user.status.deleted',
	},
	enabled: {
		value: 'enabled',
		icon: '@tui.check',
		appearance: 'success',
		text: 'user.status.enabled',
	},
	disabled: {
		value: 'disabled',
		icon: '@tui.circle-minus',
		appearance: 'error',
		text: 'user.status.disabled',
	},
	rejected: {
		value: 'rejected',
		icon: '@tui.triangle-alert',
		appearance: 'error',
		text: 'user.status.rejected',
	},
};

export function getPasswordResetUrl(token: string, email: string) {
	return `auth/reset-password?token=${token}&email=${email}`;
}
