import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Output,
} from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';
import { TuiButton, TuiIcon } from '@taiga-ui/core';
import { TuiHeader } from '@taiga-ui/layout';

@Component({
	standalone: true,
	imports: [TranslocoModule, TuiButton, TuiHeader, TuiIcon],
	selector: 'ctx-logout-dialog',
	templateUrl: 'logout-dialog.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LogoutDialogComponent {
	@Output() dismiss = new EventEmitter<boolean>();

	onDismiss() {
		this.dismiss.emit(false);
	}

	onSubmit() {
		this.dismiss.emit(true);
	}
}
