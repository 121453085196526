@if (src) {
	<tui-avatar
		[size]="size"
		tuiAvatarOutline
		appearance="primary"
		[src]="src | tuiFallbackSrc: initials | async"
		(click)="onUploadImage()"
	/>
} @else {
	<tui-avatar
		[size]="size"
		tuiAvatarOutline
		[src]="icon | tuiIcon"
		(click)="onUploadImage()"
	/>
}

@if (canUpload) {
	<input
		#fileUploadInput
		class="avatar__file-upload"
		name="avatar"
		type="file"
		[disabled]="disabled"
		[accept]="accept"
		[multiple]="false"
		(change)="onFileChange(imageCropper)"
	/>

	<button
		tuiButton
		size="xs"
		iconStart="@tui.upload"
		[disabled]="disabled"
		appearance="outline"
		(click)="onUploadImage()"
	>
		{{ 'profile.action.upload-image' | transloco }}
	</button>

	<!-- only show the remove button if there is a thumbnail -->
	@if (user?.thumbnailUrl) {
		<button
			tuiButton
			size="xs"
			iconStart="@tui.x"
			[disabled]="disabled"
			appearance="ghost"
			(click)="onRemoveImage()"
		>
			{{ 'profile.action.remove-image' | transloco }}
		</button>
	}
}

<ng-template #imageCropper>
	<ctx-image-cropper-dialog
		[imageFile]="imageFile"
		(dismiss)="onImageCropperDismiss($event)"
	></ctx-image-cropper-dialog>
</ng-template>
