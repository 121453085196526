import * as i0 from '@angular/core';
import { inject, Component, ChangeDetectionStrategy, ViewChild, HostBinding, Directive, NgModule } from '@angular/core';
import * as i1 from '@taiga-ui/core/directives/hint';
import { TuiHintOptionsDirective, TuiHint } from '@taiga-ui/core/directives/hint';
import { TUI_PASSWORD_TEXTS } from '@taiga-ui/kit/tokens';
import { AbstractTuiControl, tuiAsControl, AbstractTuiTextfieldHost } from '@taiga-ui/legacy/classes';
import * as i4 from '@taiga-ui/legacy/components/primitive-textfield';
import { TuiPrimitiveTextfieldComponent, TuiPrimitiveTextfieldModule, TuiTextfieldComponent } from '@taiga-ui/legacy/components/primitive-textfield';
import * as i5 from '@taiga-ui/legacy/directives';
import { TUI_TEXTFIELD_SIZE, TuiTextfieldControllerModule } from '@taiga-ui/legacy/directives';
import { tuiAsFocusableItemAccessor, tuiAsTextfieldHost } from '@taiga-ui/legacy/tokens';
import { EMPTY, startWith, map } from 'rxjs';
import { tuiCreateToken, tuiProvideOptions } from '@taiga-ui/cdk/utils/miscellaneous';
import * as i2 from '@angular/common';
import { CommonModule } from '@angular/common';
import * as i3 from '@taiga-ui/polymorpheus';
import { PolymorpheusOutlet, PolymorpheusTemplate } from '@taiga-ui/polymorpheus';
import * as i6 from '@taiga-ui/core/directives/appearance';
import { TuiAppearance } from '@taiga-ui/core/directives/appearance';
import * as i7 from '@taiga-ui/core/components/icon';
import { TuiIcon } from '@taiga-ui/core/components/icon';
import { tuiIsInput } from '@taiga-ui/cdk/utils/dom';
import { FormsModule } from '@angular/forms';
const _c0 = ["*", [["input"]]];
const _c1 = ["*", "input"];
const _c2 = a0 => ({
  $implicit: a0
});
function TuiInputPasswordComponent_ng_template_3_ng_container_0_tui_icon_1_Template(rf, ctx) {
  if (rf & 1) {
    const _r2 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "tui-icon", 7);
    i0.ɵɵpipe(1, "async");
    i0.ɵɵlistener("click", function TuiInputPasswordComponent_ng_template_3_ng_container_0_tui_icon_1_Template_tui_icon_click_0_listener() {
      i0.ɵɵrestoreView(_r2);
      const ctx_r2 = i0.ɵɵnextContext(3);
      return i0.ɵɵresetView(ctx_r2.togglePasswordVisibility());
    })("mousedown.silent.prevent", function TuiInputPasswordComponent_ng_template_3_ng_container_0_tui_icon_1_Template_tui_icon_mousedown_silent_prevent_0_listener() {
      i0.ɵɵrestoreView(_r2);
      return i0.ɵɵresetView(0);
    })("mouseup.silent.prevent", function TuiInputPasswordComponent_ng_template_3_ng_container_0_tui_icon_1_Template_tui_icon_mouseup_silent_prevent_0_listener() {
      i0.ɵɵrestoreView(_r2);
      return i0.ɵɵresetView(0);
    });
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const src_r4 = ctx.polymorpheusOutlet;
    const texts_r5 = i0.ɵɵnextContext().ngIf;
    const hintContent_r6 = i0.ɵɵreference(3);
    const ctx_r2 = i0.ɵɵnextContext(2);
    i0.ɵɵclassProp("t-icon_small", ctx_r2.size === "s");
    i0.ɵɵproperty("icon", src_r4.toString())("tuiHint", texts_r5[0] && texts_r5[1] && hintContent_r6)("tuiHintAppearance", i0.ɵɵpipeBind1(1, 6, ctx_r2.computedAppearance$) || "")("tuiHintDirection", (ctx_r2.hintOptions == null ? null : ctx_r2.hintOptions.direction) || "bottom-left");
  }
}
function TuiInputPasswordComponent_ng_template_3_ng_container_0_ng_template_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtext(0);
  }
  if (rf & 2) {
    const texts_r5 = i0.ɵɵnextContext().ngIf;
    const ctx_r2 = i0.ɵɵnextContext(2);
    i0.ɵɵtextInterpolate1(" ", ctx_r2.isPasswordHidden ? texts_r5[0] : texts_r5[1], " ");
  }
}
function TuiInputPasswordComponent_ng_template_3_ng_container_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, TuiInputPasswordComponent_ng_template_3_ng_container_0_tui_icon_1_Template, 2, 8, "tui-icon", 5)(2, TuiInputPasswordComponent_ng_template_3_ng_container_0_ng_template_2_Template, 1, 1, "ng-template", 6, 1, i0.ɵɵtemplateRefExtractor);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance();
    i0.ɵɵproperty("polymorpheusOutlet", ctx_r2.icon)("polymorpheusOutletContext", i0.ɵɵpureFunction1(2, _c2, ctx_r2.size));
  }
}
function TuiInputPasswordComponent_ng_template_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, TuiInputPasswordComponent_ng_template_3_ng_container_0_Template, 4, 4, "ng-container", 4);
    i0.ɵɵpipe(1, "async");
  }
  if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵproperty("ngIf", i0.ɵɵpipeBind1(1, 1, ctx_r2.passwordTexts$));
  }
}
const TUI_INPUT_PASSWORD_DEFAULT_OPTIONS = {
  icons: {
    hide: () => '@tui.eye',
    show: () => '@tui.eye-off'
  }
};
const TUI_INPUT_PASSWORD_OPTIONS = tuiCreateToken(TUI_INPUT_PASSWORD_DEFAULT_OPTIONS);
function tuiInputPasswordOptionsProvider(options) {
  return tuiProvideOptions(TUI_INPUT_PASSWORD_OPTIONS, options, TUI_INPUT_PASSWORD_DEFAULT_OPTIONS);
}
class TuiInputPasswordComponent extends AbstractTuiControl {
  constructor() {
    super(...arguments);
    this.textfieldSize = inject(TUI_TEXTFIELD_SIZE);
    this.hintOptions = inject(TuiHintOptionsDirective, {
      optional: true
    });
    this.directive$ = this.hintOptions?.change$ || EMPTY;
    this.isPasswordHidden = true;
    this.computedAppearance$ = this.directive$.pipe(startWith(null), map(() => this.hintOptions?.appearance || ''), startWith(''));
    this.passwordTexts$ = inject(TUI_PASSWORD_TEXTS);
    this.options = inject(TUI_INPUT_PASSWORD_OPTIONS);
  }
  get nativeFocusableElement() {
    return this.computedDisabled || !this.textfield ? null : this.textfield.nativeFocusableElement;
  }
  get focused() {
    return !!this.textfield?.focused;
  }
  get inputType() {
    return this.isPasswordHidden || !this.interactive ? 'password' : 'text';
  }
  onValueChange(textValue) {
    this.value = textValue;
  }
  get size() {
    return this.textfieldSize.size;
  }
  get icon() {
    return this.isPasswordHidden ? this.options.icons.show : this.options.icons.hide;
  }
  onFocused(focused) {
    this.updateFocused(focused);
  }
  togglePasswordVisibility() {
    this.isPasswordHidden = !this.isPasswordHidden;
  }
  getFallbackValue() {
    return '';
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵTuiInputPasswordComponent_BaseFactory;
      return function TuiInputPasswordComponent_Factory(__ngFactoryType__) {
        return (ɵTuiInputPasswordComponent_BaseFactory || (ɵTuiInputPasswordComponent_BaseFactory = i0.ɵɵgetInheritedFactory(TuiInputPasswordComponent)))(__ngFactoryType__ || TuiInputPasswordComponent);
      };
    })();
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: TuiInputPasswordComponent,
      selectors: [["tui-input-password"]],
      viewQuery: function TuiInputPasswordComponent_Query(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵviewQuery(TuiPrimitiveTextfieldComponent, 5);
        }
        if (rf & 2) {
          let _t;
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.textfield = _t.first);
        }
      },
      hostVars: 1,
      hostBindings: function TuiInputPasswordComponent_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵattribute("data-size", ctx.size);
        }
      },
      features: [i0.ɵɵProvidersFeature([tuiAsFocusableItemAccessor(TuiInputPasswordComponent), tuiAsControl(TuiInputPasswordComponent)]), i0.ɵɵInheritDefinitionFeature],
      ngContentSelectors: _c1,
      decls: 5,
      vars: 11,
      consts: [["iconContent", "polymorpheus"], ["hintContent", "polymorpheus"], [1, "t-textfield", 3, "valueChange", "focusedChange", "disabled", "focusable", "invalid", "nativeId", "pseudoActive", "pseudoFocus", "pseudoHover", "readOnly", "tuiTextfieldIcon", "value"], [3, "polymorpheus"], [4, "ngIf"], ["automation-id", "tui-password__icon", "tuiAppearance", "icon", "class", "t-icon", 3, "t-icon_small", "icon", "tuiHint", "tuiHintAppearance", "tuiHintDirection", "click", "mousedown.silent.prevent", "mouseup.silent.prevent", 4, "polymorpheusOutlet", "polymorpheusOutletContext"], ["polymorpheus", ""], ["automation-id", "tui-password__icon", "tuiAppearance", "icon", 1, "t-icon", 3, "click", "mousedown.silent.prevent", "mouseup.silent.prevent", "icon", "tuiHint", "tuiHintAppearance", "tuiHintDirection"]],
      template: function TuiInputPasswordComponent_Template(rf, ctx) {
        if (rf & 1) {
          const _r1 = i0.ɵɵgetCurrentView();
          i0.ɵɵprojectionDef(_c0);
          i0.ɵɵelementStart(0, "tui-primitive-textfield", 2);
          i0.ɵɵtwoWayListener("valueChange", function TuiInputPasswordComponent_Template_tui_primitive_textfield_valueChange_0_listener($event) {
            i0.ɵɵrestoreView(_r1);
            i0.ɵɵtwoWayBindingSet(ctx.value, $event) || (ctx.value = $event);
            return i0.ɵɵresetView($event);
          });
          i0.ɵɵlistener("focusedChange", function TuiInputPasswordComponent_Template_tui_primitive_textfield_focusedChange_0_listener($event) {
            i0.ɵɵrestoreView(_r1);
            return i0.ɵɵresetView(ctx.onFocused($event));
          });
          i0.ɵɵprojection(1);
          i0.ɵɵprojection(2, 1, ["ngProjectAs", "input", 5, ["input"]]);
          i0.ɵɵelementEnd();
          i0.ɵɵtemplate(3, TuiInputPasswordComponent_ng_template_3_Template, 2, 3, "ng-template", 3, 0, i0.ɵɵtemplateRefExtractor);
        }
        if (rf & 2) {
          const iconContent_r7 = i0.ɵɵreference(4);
          i0.ɵɵproperty("disabled", ctx.computedDisabled)("focusable", ctx.focusable)("invalid", ctx.computedInvalid)("nativeId", ctx.nativeId)("pseudoActive", ctx.pseudoActive)("pseudoFocus", ctx.pseudoFocus)("pseudoHover", ctx.pseudoHover)("readOnly", ctx.readOnly)("tuiTextfieldIcon", ctx.interactive ? iconContent_r7 : "");
          i0.ɵɵtwoWayProperty("value", ctx.value);
          i0.ɵɵadvance(3);
          i0.ɵɵproperty("polymorpheus", ctx.type);
        }
      },
      dependencies: [i1.TuiHintDirective, i2.NgIf, i3.PolymorpheusOutlet, i3.PolymorpheusTemplate, i4.TuiPrimitiveTextfieldComponent, i4.TuiPrimitiveTextfieldDirective, i5.TuiTextfieldIconDirective, i6.TuiAppearance, i7.TuiIcon, i2.AsyncPipe],
      styles: ["[_nghost-%COMP%]{display:block;border-radius:var(--tui-radius-m);text-align:left}.t-icon[_ngcontent-%COMP%]{cursor:pointer;pointer-events:auto}.t-icon_small[_ngcontent-%COMP%]{border:.25rem solid transparent}.t-textfield[_ngcontent-%COMP%]{border-radius:inherit;text-align:inherit}"],
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiInputPasswordComponent, [{
    type: Component,
    args: [{
      selector: 'tui-input-password',
      changeDetection: ChangeDetectionStrategy.OnPush,
      providers: [tuiAsFocusableItemAccessor(TuiInputPasswordComponent), tuiAsControl(TuiInputPasswordComponent)],
      template: "<tui-primitive-textfield\n    class=\"t-textfield\"\n    [disabled]=\"computedDisabled\"\n    [focusable]=\"focusable\"\n    [invalid]=\"computedInvalid\"\n    [nativeId]=\"nativeId\"\n    [pseudoActive]=\"pseudoActive\"\n    [pseudoFocus]=\"pseudoFocus\"\n    [pseudoHover]=\"pseudoHover\"\n    [readOnly]=\"readOnly\"\n    [tuiTextfieldIcon]=\"interactive ? iconContent : ''\"\n    [(value)]=\"value\"\n    (focusedChange)=\"onFocused($event)\"\n>\n    <ng-content />\n    <ng-content\n        ngProjectAs=\"input\"\n        select=\"input\"\n    />\n</tui-primitive-textfield>\n\n<ng-template\n    #iconContent=\"polymorpheus\"\n    [polymorpheus]=\"type\"\n>\n    <ng-container *ngIf=\"passwordTexts$ | async as texts\">\n        <tui-icon\n            *polymorpheusOutlet=\"icon as src; context: {$implicit: size}\"\n            automation-id=\"tui-password__icon\"\n            tuiAppearance=\"icon\"\n            class=\"t-icon\"\n            [class.t-icon_small]=\"size === 's'\"\n            [icon]=\"src.toString()\"\n            [tuiHint]=\"texts[0] && texts[1] && hintContent\"\n            [tuiHintAppearance]=\"(computedAppearance$ | async) || ''\"\n            [tuiHintDirection]=\"hintOptions?.direction || 'bottom-left'\"\n            (click)=\"togglePasswordVisibility()\"\n            (mousedown.silent.prevent)=\"(0)\"\n            (mouseup.silent.prevent)=\"(0)\"\n        />\n\n        <ng-template\n            #hintContent=\"polymorpheus\"\n            polymorpheus\n        >\n            {{ isPasswordHidden ? texts[0] : texts[1] }}\n        </ng-template>\n    </ng-container>\n</ng-template>\n",
      styles: [":host{display:block;border-radius:var(--tui-radius-m);text-align:left}.t-icon{cursor:pointer;pointer-events:auto}.t-icon_small{border:.25rem solid transparent}.t-textfield{border-radius:inherit;text-align:inherit}\n"]
    }]
  }], null, {
    textfield: [{
      type: ViewChild,
      args: [TuiPrimitiveTextfieldComponent]
    }],
    size: [{
      type: HostBinding,
      args: ['attr.data-size']
    }]
  });
})();
class TuiInputPasswordDirective extends AbstractTuiTextfieldHost {
  onValueChange(value) {
    this.host.onValueChange(value);
  }
  process(input) {
    this.input = input;
  }
  ngDoCheck() {
    if (this.host.nativeFocusableElement && tuiIsInput(this.host.nativeFocusableElement)) {
      this.host.nativeFocusableElement.type = this.host.inputType;
    }
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵTuiInputPasswordDirective_BaseFactory;
      return function TuiInputPasswordDirective_Factory(__ngFactoryType__) {
        return (ɵTuiInputPasswordDirective_BaseFactory || (ɵTuiInputPasswordDirective_BaseFactory = i0.ɵɵgetInheritedFactory(TuiInputPasswordDirective)))(__ngFactoryType__ || TuiInputPasswordDirective);
      };
    })();
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: TuiInputPasswordDirective,
      selectors: [["tui-input-password"]],
      features: [i0.ɵɵProvidersFeature([tuiAsTextfieldHost(TuiInputPasswordDirective)]), i0.ɵɵInheritDefinitionFeature]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiInputPasswordDirective, [{
    type: Directive,
    args: [{
      selector: 'tui-input-password',
      providers: [tuiAsTextfieldHost(TuiInputPasswordDirective)]
    }]
  }], null, null);
})();
class TuiInputPasswordModule {
  static {
    this.ɵfac = function TuiInputPasswordModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || TuiInputPasswordModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: TuiInputPasswordModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [i1.TuiHintComponent, CommonModule, FormsModule, TuiPrimitiveTextfieldModule, TuiTextfieldControllerModule, TuiIcon]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiInputPasswordModule, [{
    type: NgModule,
    args: [{
      imports: [...TuiHint, CommonModule, FormsModule, PolymorpheusOutlet, PolymorpheusTemplate, TuiPrimitiveTextfieldModule, TuiTextfieldControllerModule, TuiAppearance, TuiIcon],
      declarations: [TuiInputPasswordComponent, TuiInputPasswordDirective],
      exports: [TuiInputPasswordComponent, TuiInputPasswordDirective, TuiTextfieldComponent, ...TuiHint]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { TUI_INPUT_PASSWORD_DEFAULT_OPTIONS, TUI_INPUT_PASSWORD_OPTIONS, TuiInputPasswordComponent, TuiInputPasswordDirective, TuiInputPasswordModule, tuiInputPasswordOptionsProvider };
