import { Inject, Injectable } from '@angular/core';
import {
	deleteObject,
	getDownloadURL,
	ref,
	Storage,
} from '@angular/fire/storage';
import { ORGANIZATION_ID } from '@context/frontend/api-client';
import imageCompression from 'browser-image-compression';
import { uploadBytes } from 'firebase/storage';
import { BehaviorSubject } from 'rxjs';
import { v7 } from 'uuid';

export type ImageCompressionOptions = {
	maxSizeMB?: number;
	maxWidthOrHeight?: number;
};

// config is the organization's additional configurations
type EntityType = 'users' | 'config' | 'files' | 'folders';

@Injectable({ providedIn: 'root' })
export class StorageService {
	constructor(
		private readonly storage: Storage,
		@Inject(ORGANIZATION_ID)
		private readonly organizationId: BehaviorSubject<string | null>,
	) {}

	/**
	 * Uploads the provided file to the angular firebase storage and returns a task to follow
	 * @param file The file to upload to the firebase storage
	 * @param options additional options to further configure how the file will be uploaded
	 * @returns A task observable to follow the progress
	 */
	async uploadFile(
		file: File,
		options: {
			entityType: EntityType;
			compress?: boolean;
			compressOptions?: ImageCompressionOptions;
		},
	) {
		const isImage = file.type.startsWith('image/');
		if (isImage) {
			const compress = options.compress ?? true;
			if (compress) {
				file = await this.compressImage(
					file,
					options.compressOptions ?? undefined,
				);
			}
		}

		const { entityType } = options;
		const path = this.path(v7(), this.getExtension(file), entityType);
		const storageRef = ref(this.storage, path);

		const task = await uploadBytes(storageRef, file);
		return getDownloadURL(task.ref);
	}

	/**
	 * Deletes the file located at the provided url if located in firebase
	 * @param url the url to where the file is located
	 * @returns observable task of the deletion
	 */
	deleteFromURL(url: string) {
		return deleteObject(ref(this.storage, url));
	}

	/**
	 * Determines what the extension of the file is and returns it
	 * @param file The file to get the type from
	 * @returns the extension of the file
	 */
	getExtension(file: File) {
		if (file.type) {
			return `.${file.type.split('/')[1]}`;
		} else if (file.name) {
			return `.${file.name.split('.')[1]}`;
		}
		return '.txt';
	}

	/**
	 * Compresses the provided image to a more performant storable file.
	 * Prevents users from uploading a very large image into the database.
	 * @param file The file to compress
	 * @returns The newly compressed file
	 */
	compressImage(file: File, options?: ImageCompressionOptions) {
		return imageCompression(file, {
			maxSizeMB: 0.5, // TODO: Make global value
			maxWidthOrHeight: 256, // TODO: Make global value
			...options,
		});
	}

	/**
	 * @param fileName the name of the file to append to back of the path
	 * @param ext the extension of the file to append to the file name
	 * @param id optional id based on where the path should organize the file
	 */
	path(fileName: string, ext: string, entityType?: EntityType) {
		const organizationId = this.organizationId?.value;
		if (!organizationId)
			throw new Error('No organization provided to determine path');

		let value = `/organizations/${organizationId}`;
		if (entityType) value += `/${entityType}`;
		return `${value}/${fileName}${ext}`;
	}
}
