<header tuiHeader>
	<h3 tuiTitle class="margin-0">{{ 'navigation.sign-out' | transloco }}</h3>

	<div tuiAccessories>
		<button tuiIconButton size="m" (click)="onDismiss()" appearance="ghost">
			<tui-icon icon="@tui.x"></tui-icon>
		</button>
	</div>
</header>

<div class="logout__content">
	<p>{{ 'auth.sign-out-description' | transloco }}</p>
</div>

<footer tuiFooter>
	<button tuiButton appearance="outline" (click)="onDismiss()">
		{{ 'action.no' | transloco }}
	</button>
	<button tuiButton type="submit" appearance="accent" (click)="onSubmit()">
		{{ 'action.yes' | transloco }}
	</button>
</footer>
