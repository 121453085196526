import * as i0 from '@angular/core';
import { InjectionToken, Injector, inject, TemplateRef, ChangeDetectorRef, Directive, ViewContainerRef, INJECTOR, Input } from '@angular/core';

/**
 * Use this token to access context within your components when
 * instantiating them through {@link PolymorpheusOutlet}
 */
const POLYMORPHEUS_CONTEXT = new InjectionToken('POLYMORPHEUS_CONTEXT');

/**
 * Wrapper class for a component that will be used as content for {@link PolymorpheusOutlet}
 *
 * @param component — an Angular component to be dynamically created
 * @param injector — optional {@link Injector} for lazy loaded module case
 */
class PolymorpheusComponent {
  component;
  i;
  constructor(component, i) {
    this.component = component;
    this.i = i;
  }
  createInjector(injector, useValue) {
    return Injector.create({
      parent: this.i || injector,
      providers: [{
        provide: POLYMORPHEUS_CONTEXT,
        useValue
      }]
    });
  }
}
class PolymorpheusContext {
  $implicit;
  constructor($implicit) {
    this.$implicit = $implicit;
  }
  get polymorpheusOutlet() {
    return this.$implicit;
  }
}
/**
 * @deprecated: use {@link PolymorpheusContext} instead
 * Primitive types used as content by {@link PolymorpheusOutlet}
 */
class PrimitiveContext extends PolymorpheusContext {}

/**
 * ng-template wrapper directive also stores {@link ChangeDetectorRef} to properly handle change detection.
 */
class PolymorpheusTemplate {
  template;
  cdr;
  polymorpheus = '';
  constructor(template = inject(TemplateRef, {
    self: true
  }), cdr = inject(ChangeDetectorRef)) {
    this.template = template;
    this.cdr = cdr;
  }
  static ngTemplateContextGuard(_dir, _ctx) {
    return true;
  }
  check() {
    this.cdr.markForCheck();
  }
  /** @nocollapse */
  static ɵfac = function PolymorpheusTemplate_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || PolymorpheusTemplate)(i0.ɵɵdirectiveInject(i0.TemplateRef), i0.ɵɵdirectiveInject(i0.ChangeDetectorRef));
  };
  /** @nocollapse */
  static ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
    type: PolymorpheusTemplate,
    selectors: [["ng-template", "polymorpheus", ""]],
    inputs: {
      polymorpheus: "polymorpheus"
    },
    exportAs: ["polymorpheus"],
    standalone: true
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PolymorpheusTemplate, [{
    type: Directive,
    args: [{
      standalone: true,
      selector: 'ng-template[polymorpheus]',
      inputs: ['polymorpheus'],
      exportAs: 'polymorpheus'
    }]
  }], function () {
    return [{
      type: i0.TemplateRef
    }, {
      type: i0.ChangeDetectorRef
    }];
  }, null);
})();
class PolymorpheusOutlet {
  vcr = inject(ViewContainerRef);
  i = inject(INJECTOR);
  t = inject(TemplateRef);
  c;
  content = '';
  context;
  static ngTemplateContextGuard(_dir, _ctx) {
    return true;
  }
  ngOnChanges({
    content
  }) {
    const context = this.getContext();
    this.c?.injector.get(ChangeDetectorRef).markForCheck();
    if (!content) {
      return;
    }
    this.vcr.clear();
    const proxy = context && new Proxy(context, {
      get: (_, key) => this.getContext()?.[key]
    });
    if (isComponent(this.content)) {
      this.process(this.content, proxy);
    } else if (
    // eslint-disable-next-line eqeqeq
    (context instanceof PolymorpheusContext && context.$implicit) != null) {
      this.vcr.createEmbeddedView(this.template, proxy, {
        injector: this.i
      });
    }
  }
  ngDoCheck() {
    if (isDirective(this.content)) {
      this.content.check();
    }
  }
  get template() {
    if (isDirective(this.content)) {
      return this.content.template;
    }
    return this.content instanceof TemplateRef ? this.content : this.t;
  }
  getContext() {
    if (isTemplate(this.content) || isComponent(this.content)) {
      return this.context;
    }
    return new PolymorpheusContext(this.context && typeof this.content === 'function' ? this.content(this.context) : this.content);
  }
  process(content, proxy) {
    const injector = content.createInjector(this.i, proxy);
    this.c = this.vcr.createComponent(content.component, {
      injector
    });
  }
  /** @nocollapse */
  static ɵfac = function PolymorpheusOutlet_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || PolymorpheusOutlet)();
  };
  /** @nocollapse */
  static ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
    type: PolymorpheusOutlet,
    selectors: [["", "polymorpheusOutlet", ""]],
    inputs: {
      content: [0, "polymorpheusOutlet", "content"],
      context: [0, "polymorpheusOutletContext", "context"]
    },
    standalone: true,
    features: [i0.ɵɵNgOnChangesFeature]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PolymorpheusOutlet, [{
    type: Directive,
    args: [{
      standalone: true,
      selector: '[polymorpheusOutlet]'
    }]
  }], null, {
    content: [{
      type: Input,
      args: ['polymorpheusOutlet']
    }],
    context: [{
      type: Input,
      args: ['polymorpheusOutletContext']
    }]
  });
})();
function isDirective(content) {
  return content instanceof PolymorpheusTemplate;
}
function isComponent(content) {
  return content instanceof PolymorpheusComponent;
}
function isTemplate(content) {
  return isDirective(content) || content instanceof TemplateRef;
}

/**
 * Public API Surface of ng-polymorpheus
 */

/**
 * Generated bundle index. Do not edit.
 */

export { POLYMORPHEUS_CONTEXT, PolymorpheusComponent, PolymorpheusOutlet, PolymorpheusTemplate };
