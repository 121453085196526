<ctx-avatar class="user-row__avatar" size="l" [user]="user"></ctx-avatar>

@if (user | userStatus; as status) {
	<div class="user-row__information">
		@if (user | userName; as name) {
			<p class="margin-0 font-size-medium">{{ user | userName }}</p>
		} @else {
			<p class="margin-0 font-size-medium">{{ user.email }}</p>
		}

		<small>
			@if (status.value === 'pending') {
				{{
					'user.invited-relative-time'
						| transloco: { time: (user.createdAt | relativeTime) }
				}}
			} @else {
				{{ user.email }}
			}
		</small>
	</div>

	<div class="user-row__actions">
		<tui-badge [appearance]="status.appearance">
			<tui-icon [icon]="status.icon"></tui-icon>
			{{ status.text | transloco }}
		</tui-badge>
		<tui-icon icon="@tui.arrow-right"></tui-icon>
	</div>
}
