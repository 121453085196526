import { Injectable } from '@angular/core';
import { CrudService } from '@context/frontend/api-client';
import { StorageService } from '@context/frontend/storage';
import {
	InviteUserDto,
	User,
	VerifyForgotPasswordResponse,
} from '@context/shared/types/user';
import { Timestamp } from 'firebase/firestore';
import { firstValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class UserService extends CrudService<User> {
	static readonly CollectionId = 'users';

	constructor(private readonly storageService: StorageService) {
		super(UserService.CollectionId);
	}

	/**
	 * Uploads a thumbnail image on behalf of a user, and returns the upload url to apply
	 * to the user in the implementation. This will also detect if the old thumbnail needs to
	 * be removed and remove it.
	 *
	 * @param file the file (or lack thereof) to update the user with
	 */
	async updateThumbnail(
		userData: User | null,
		newUserData: Partial<User>,
		file: File | null,
	): Promise<{ url: string | null; updated: boolean }> {
		const previousUrl = userData?.thumbnailUrl;

		// if the new user doesn't have a thumbnail, but the old one did, lets delete
		let deleteThumbnail = !newUserData.thumbnailUrl && !!previousUrl;

		const hasThumbnailChanged = file || deleteThumbnail;
		if (!hasThumbnailChanged) return { url: null, updated: false };

		let url: string | null = null;
		if (file) {
			url = await this.storageService
				.uploadFile(file, {
					entityType: 'users',
				})
				.catch((error) => {
					console.error(error);
					throw new Error(
						'UserService.updateThumbnail: There was an issue uploading the file',
					);
				});
			deleteThumbnail = true;
		}

		// lets wait to delete the thumbnail until we determine the new thumbnail is successful
		if (previousUrl && deleteThumbnail) {
			// no reason to wait for it
			this.storageService.deleteFromURL(previousUrl).catch((error) => {
				console.error(
					'UserService.updateThumbnail: There was an issue removing the old image from:',
					previousUrl,
					error,
				);
			});
		}

		return { url, updated: true };
	}

	invite(payload: InviteUserDto) {
		return firstValueFrom(
			this.http.post(`${this.api}/v1/users/invite`, payload),
		);
	}

	resendInvite(user: User) {
		return firstValueFrom(
			this.http.get(`${this.api}/v1/users/invite/${user.email}/resend`),
		);
	}

	async disable(id: string) {
		const res = await this.update(
			{ id, disabledAt: Timestamp.now() },
			{ addActivityLog: true, type: 'disable' },
		);

		firstValueFrom(
			this.http.get(`${this.api}/v1/users/${id}/send-email?type=disable`),
		);

		return res;
	}

	async enable(id: string) {
		const res = await this.update(
			{ id, disabledAt: null },
			{ addActivityLog: true, type: 'enable' },
		);

		firstValueFrom(
			this.http.get(`${this.api}/v1/users/${id}/send-email?type=enable`),
		);

		return res;
	}

	override async delete(id: string) {
		const res = await super.delete(id);

		firstValueFrom(
			this.http.get(`${this.api}/v1/users/${id}/send-email?type=delete`),
		);

		return res;
	}

	forgotPassword(email: string) {
		return firstValueFrom(
			this.http.post(`${this.api}/v1/users/forgot-password`, { email }),
		);
	}

	verifyForgotPasswordToken(payload: {
		email: string;
		token: string;
	}): Promise<VerifyForgotPasswordResponse> {
		return firstValueFrom(
			this.http.post<VerifyForgotPasswordResponse>(
				`${this.api}/v1/users/forgot-password/verify`,
				payload,
			),
		);
	}

	resetPassword(payload: { email: string; password: string; token: string }) {
		return firstValueFrom(
			this.http.post(`${this.api}/v1/users/reset-password`, payload),
		);
	}
}
