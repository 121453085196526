<header tuiHeader>
	<h2 tuiTitle class="margin-0">
		{{ 'auth.sign-in' | transloco }}
	</h2>

	<div tuiAccessories>
		<button tuiIconButton size="m" (click)="onDismiss()" appearance="ghost">
			<tui-icon icon="@tui.x"></tui-icon>
		</button>
	</div>
</header>

@if (message) {
	<p>{{ message }}</p>
}

@if (error) {
	<tui-notification
		size="s"
		class="authentication-dialog__notification"
		appearance="error"
	>
		{{ error | transloco }}
	</tui-notification>
}

@if (form) {
	<form
		class="authentication-dialog__form"
		[formGroup]="form"
		(ngSubmit)="onSubmit()"
		(keyup.enter)="onSubmit()"
	>
		<label tuiLabel>
			{{ 'data.email' | transloco }}

			<tui-textfield tuiTextfieldSize="m">
				<input
					formControlName="email"
					tuiTextfield
					type="email"
					autocomplete="email"
				/>
			</tui-textfield>

			<tui-error
				formControlName="email"
				[error]="['required'] | tuiFieldError | async"
			/>
		</label>

		<label tuiLabel>
			{{ 'data.password' | transloco }}

			<tui-input-password
				formControlName="password"
				tuiTextfieldSize="m"
				[tuiTextfieldLabelOutside]="true"
			>
				<input
					tuiTextfieldLegacy
					type="password"
					autocomplete="current-password"
				/>
			</tui-input-password>

			<tui-error
				formControlName="password"
				[error]="[] | tuiFieldError | async"
			/>
		</label>
	</form>
}

<footer tuiFooter>
	<button tuiButton appearance="outline" (click)="onDismiss()">
		{{ 'action.cancel' | transloco }}
	</button>
	<button
		tuiButton
		type="submit"
		appearance="accent"
		(click)="onSubmit()"
		[disabled]="busy"
		[loading]="busy"
	>
		{{ 'auth.sign-in' | transloco }}
	</button>
</footer>
