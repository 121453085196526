import { AsyncPipe } from '@angular/common';
import {
	ChangeDetectionStrategy,
	Component,
	ElementRef,
	EventEmitter,
	HostBinding,
	Input,
	Output,
	ViewChild,
} from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';
import {
	DestroyService,
	ImageCropperDialogComponent,
} from '@context/frontend/common';
import { getInitials, getName, User } from '@context/shared/types/user';
import {
	TuiButton,
	TuiDialogService,
	TuiFallbackSrcPipe,
	TuiIcon,
	TuiIconPipe,
	TuiSizeS,
	TuiSizeXXL,
	TuiTitle,
} from '@taiga-ui/core';
import { TuiAvatar, TuiAvatarOutline } from '@taiga-ui/kit';
import { PolymorpheusContent } from '@tinkoff/ng-polymorpheus';
import { catchError, of, Subscription, take } from 'rxjs';

const FileAcceptImageTypes = ['*/*', 'image/png', 'image/jpg', 'image/jpeg'];
export type FileAcceptImageType = (typeof FileAcceptImageTypes)[number];

@Component({
	standalone: true,
	imports: [
		TuiAvatar,
		TuiButton,
		TuiIcon,
		TuiFallbackSrcPipe,
		AsyncPipe,
		TuiIconPipe,
		TuiTitle,
		TuiAvatarOutline,
		TranslocoModule,
		ImageCropperDialogComponent,
	],
	selector: 'ctx-avatar',
	templateUrl: 'avatar.component.html',
	styleUrl: 'avatar.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [DestroyService],
})
export class AvatarComponent {
	@ViewChild('fileUploadInput')
	fileUploadInputRef!: ElementRef<HTMLInputElement>;

	@Input() icon = '@tui.user';

	@Input() user: User | null = null;

	@Input() size: TuiSizeS | TuiSizeXXL = 'm';

	@HostBinding('attr.disabled')
	@Input()
	disabled = false;

	@HostBinding('attr.can-click')
	@HostBinding('tabindex.0')
	@Input()
	canUpload = false;

	@Output() fileChange = new EventEmitter<File>();
	@Output() fileRemove = new EventEmitter<void>();

	readonly accept = FileAcceptImageTypes.join(', ');

	/**
	 * used for the cropper, should only be used until cropper has resolved and then remove
	 */
	imageFile: File | null = null;

	imageCropperSubscription: Subscription | null = null;

	get src() {
		if (!this.user) return null;
		if (this.user.thumbnailUrl) return this.user.thumbnailUrl;
		return this.initials;
	}

	get initials() {
		if (!this.user || (!this.user.familyName && !this.user.givenName))
			return '';
		return getInitials(getName(this.user)) ?? '';
	}

	constructor(private readonly dialogService: TuiDialogService) {}

	onUploadImage() {
		if (!this.canUpload) return;
		this.fileUploadInputRef.nativeElement?.click();
	}

	onRemoveImage() {
		this.fileRemove.emit();
	}

	onFileChange(imageCropper: PolymorpheusContent) {
		const fileInput = this.fileUploadInputRef.nativeElement;

		this.imageFile = fileInput.files?.item(0) ?? null;
		if (this.imageFile) {
			this.imageCropperSubscription = this.dialogService
				.open(imageCropper, { closeable: false })
				.pipe(
					take(1),
					catchError(() => of(null)),
				)
				.subscribe();
		}
		return Promise.resolve();
	}

	onImageCropperDismiss(event: File | null) {
		this.imageCropperSubscription?.unsubscribe();
		this.imageCropperSubscription = null;
		this.imageFile = null;
		this.fileUploadInputRef.nativeElement.value = '';

		if (!event) return;
		this.fileChange.emit(event);
	}
}
