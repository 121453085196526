import { DOCUMENT } from '@angular/common';
import * as i0 from '@angular/core';
import { inject, Directive, HostListener } from '@angular/core';
import { tuiInjectElement, tuiIsHTMLElement, tuiContainsOrAfter } from '@taiga-ui/cdk/utils/dom';
import { tuiGetNativeFocused, tuiBlurNativeFocused, tuiGetClosestFocusable } from '@taiga-ui/cdk/utils/focus';
class TuiFocusTrap {
  constructor() {
    this.doc = inject(DOCUMENT);
    this.el = tuiInjectElement();
    this.activeElement = tuiGetNativeFocused(this.doc);
    /**
     * This would cause currently focused element to lose focus,
     * but it might cause ExpressionChanged error due to potential HostBinding.
     * Microtask keeps it in the same frame but allows change detection to run
     */
    void Promise.resolve().then(() => this.el.focus());
  }
  ngOnDestroy() {
    tuiBlurNativeFocused(this.doc);
    /**
     * HostListeners are triggered even after ngOnDestroy
     * {@link https://github.com/angular/angular/issues/38100}
     * so we need to delay it but stay in the same sync cycle,
     * therefore using Promise instead of setTimeout
     */
    // eslint-disable-next-line
    Promise.resolve().then(() => {
      if (tuiIsHTMLElement(this.activeElement)) {
        this.activeElement.focus();
      }
    });
  }
  onFocusIn(node) {
    if (!tuiContainsOrAfter(this.el, node)) {
      tuiGetClosestFocusable({
        initial: this.el,
        root: this.el
      })?.focus();
    }
  }
  static {
    this.ɵfac = function TuiFocusTrap_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || TuiFocusTrap)();
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: TuiFocusTrap,
      selectors: [["", "tuiFocusTrap", ""]],
      hostAttrs: ["tabIndex", "0"],
      hostBindings: function TuiFocusTrap_HostBindings(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵlistener("focusin.silent", function TuiFocusTrap_focusin_silent_HostBindingHandler($event) {
            return ctx.onFocusIn($event.target);
          }, false, i0.ɵɵresolveWindow);
        }
      },
      standalone: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiFocusTrap, [{
    type: Directive,
    args: [{
      standalone: true,
      selector: '[tuiFocusTrap]',
      host: {
        tabIndex: '0'
      }
    }]
  }], function () {
    return [];
  }, {
    onFocusIn: [{
      type: HostListener,
      args: ['window:focusin.silent', ['$event.target']]
    }]
  });
})();

/**
 * Generated bundle index. Do not edit.
 */

export { TuiFocusTrap };
