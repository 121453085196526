import { Activity, ActivityType } from '../types';

export const ActivityMap: {
	[key in ActivityType]: Pick<Activity, 'type' | 'content'>;
} = {
	create: {
		type: 'create',
		content: 'activity.type.create',
	},
	delete: {
		type: 'delete',
		content: 'activity.type.delete',
	},
	update: {
		type: 'update',
		content: 'activity.type.updated',
	},
	archive: {
		type: 'archive',
		content: 'activity.type.archive',
	},
	unarchive: {
		type: 'unarchive',
		content: 'activity.type.unarchive',
	},
	share: {
		type: 'share',
		content: 'activity.type.share',
	},
	'revoke-share': {
		type: 'revoke-share',
		content: 'activity.type.revoke-share',
	},
	ownership: {
		type: 'ownership',
		content: 'activity.type.ownership',
	},
	'revoke-ownership': {
		type: 'revoke-ownership',
		content: 'activity.type.revoke-ownership',
	},
	disable: {
		type: 'disable',
		content: 'activity.type.disable',
	},
	enable: {
		type: 'enable',
		content: 'activity.type.enable',
	},
	'move-to': {
		type: 'move-to',
		content: 'activity.type.move-to',
	},
};
