import { Pipe, PipeTransform } from '@angular/core';
import type { Timestamp } from '@context/shared/types/common';

/**
 * @todo add translation support
 */
@Pipe({
	name: 'relativeTime',
	standalone: true,
})
export class RelativeTimePipe implements PipeTransform {
	transform(value: Timestamp | Date) {
		const now = new Date();

		let date = now;
		if (!(value instanceof Date) && value.toDate !== null) {
			date = value.toDate();
		} else if (value instanceof Date) date = value;

		const seconds = Math.floor((now.getTime() - date.getTime()) / 1000);
		let interval = Math.floor(seconds / RelativeTime.secondsInYear);

		if (interval > 1) return interval + ' years ago';

		interval = Math.floor(seconds / RelativeTime.secondsInMonth);
		if (interval > 1) return interval + ' months ago';

		interval = Math.floor(seconds / RelativeTime.secondsInDay);
		if (interval > 1) return interval + ' days ago';

		interval = Math.floor(seconds / RelativeTime.secondsInHour);
		if (interval > 1) return interval + ' hours ago';

		interval = Math.floor(seconds / RelativeTime.secondsInMinute);
		if (interval > 1) return interval + ' minutes ago';

		if (seconds < RelativeTime.secondsMinimum) return 'now';

		return Math.floor(seconds) + ' seconds ago';
	}
}

const RelativeTime = {
	secondsInYear: 31536000,
	secondsInMonth: 2592000,
	secondsInDay: 86400,
	secondsInHour: 3600,
	secondsInMinute: 60,
	secondsMinimum: 10,
};
