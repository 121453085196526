import { Component, EventEmitter, Output } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';
import { TuiButton, TuiIcon } from '@taiga-ui/core';
import { TuiHeader } from '@taiga-ui/layout';

@Component({
	standalone: true,
	imports: [TuiButton, TuiIcon, TuiHeader, TranslocoModule],
	selector: 'ctx-manage-user-dialog',
	templateUrl: 'manage-user-dialog.component.html',
})
export class ManageUserDialogComponent {
	@Output() edit = new EventEmitter();

	@Output() remove = new EventEmitter();

	@Output() dismiss = new EventEmitter();

	onEdit() {
		this.edit.emit();
	}

	onRemove() {
		this.remove.emit();
	}

	onDismiss() {
		this.dismiss.emit();
	}
}
