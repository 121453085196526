import { NgIf, AsyncPipe } from '@angular/common';
import * as i0 from '@angular/core';
import { inject, NgZone, Injectable, Directive, Input, Component, ChangeDetectionStrategy, ElementRef, HostListener, DestroyRef } from '@angular/core';
import { WA_ANIMATION_FRAME } from '@ng-web-apis/common';
import { tuiTypedFromEvent, tuiZonefree, tuiScrollFrom, tuiZoneOptimized } from '@taiga-ui/cdk/observables';
import { tuiFadeIn } from '@taiga-ui/core/animations';
import { TUI_SCROLL_REF, TUI_ANIMATIONS_SPEED } from '@taiga-ui/core/tokens';
import { tuiToAnimationOptions } from '@taiga-ui/core/utils';
import { Observable, merge, map, switchMap, takeUntil, throttleTime, startWith, distinctUntilChanged, timer } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { tuiInjectElement, tuiGetElementOffset } from '@taiga-ui/cdk/utils/dom';
import { TUI_IS_IOS } from '@taiga-ui/cdk/tokens';
import { tuiCreateToken, tuiProvideOptions, tuiProvide } from '@taiga-ui/cdk/utils/miscellaneous';
function TuiScrollControls_ng_container_0_div_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 3);
    i0.ɵɵelement(1, "div", 4);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const bars_r1 = i0.ɵɵnextContext().ngIf;
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵclassProp("t-bar_has-horizontal", bars_r1[1]);
    i0.ɵɵproperty("@tuiFadeIn", ctx_r1.options);
  }
}
function TuiScrollControls_ng_container_0_div_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 5);
    i0.ɵɵelement(1, "div", 6);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const bars_r1 = i0.ɵɵnextContext().ngIf;
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵclassProp("t-bar_has-vertical", bars_r1[0]);
    i0.ɵɵproperty("@tuiFadeIn", ctx_r1.options);
  }
}
function TuiScrollControls_ng_container_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, TuiScrollControls_ng_container_0_div_1_Template, 2, 3, "div", 1)(2, TuiScrollControls_ng_container_0_div_2_Template, 2, 3, "div", 2);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const bars_r1 = ctx.ngIf;
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", bars_r1[0]);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", bars_r1[1]);
  }
}
const _c0 = ["*"];
function TuiScrollbar_tui_scroll_controls_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "tui-scroll-controls", 2);
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵclassProp("t-hover-mode", ctx_r0.options.mode === "hover");
  }
}
class TuiScrollbarService extends Observable {
  constructor() {
    super(subscriber => this.scroll$.subscribe(subscriber));
    this.el = tuiInjectElement();
    this.element = inject(TUI_SCROLL_REF).nativeElement;
    this.scroll$ = merge(tuiTypedFromEvent(this.el.parentElement, 'mousedown').pipe(map(event => this.getScrolled(event, 0.5, 0.5))), tuiTypedFromEvent(this.el, 'mousedown').pipe(tuiZonefree(inject(NgZone)), switchMap(event => {
      const {
        ownerDocument
      } = this.el;
      const rect = this.el.getBoundingClientRect();
      const vertical = getOffsetVertical(event, rect);
      const horizontal = getOffsetHorizontal(event, rect);
      return tuiTypedFromEvent(ownerDocument, 'mousemove').pipe(map(event => this.getScrolled(event, vertical, horizontal)), takeUntil(tuiTypedFromEvent(ownerDocument, 'mouseup')));
    })));
  }
  getScrolled({
    clientY,
    clientX
  }, offsetY, offsetX) {
    const {
      offsetHeight,
      offsetWidth
    } = this.el;
    const {
      top,
      left,
      width,
      height
    } = this.el.parentElement.getBoundingClientRect();
    const maxTop = this.element.scrollHeight - height;
    const maxLeft = this.element.scrollWidth - width;
    const scrolledTop = (clientY - top - offsetHeight * offsetY) / (height - offsetHeight);
    const scrolledLeft = (clientX - left - offsetWidth * offsetX) / (width - offsetWidth);
    return [maxTop * scrolledTop, maxLeft * scrolledLeft];
  }
  static {
    this.ɵfac = function TuiScrollbarService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || TuiScrollbarService)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: TuiScrollbarService,
      factory: TuiScrollbarService.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiScrollbarService, [{
    type: Injectable
  }], function () {
    return [];
  }, null);
})();
function getOffsetVertical({
  clientY
}, {
  top,
  height
}) {
  return (clientY - top) / height;
}
function getOffsetHorizontal({
  clientX
}, {
  left,
  width
}) {
  return (clientX - left) / width;
}
const MIN_WIDTH = 24;
class TuiScrollbarDirective {
  constructor() {
    this.el = inject(TUI_SCROLL_REF).nativeElement;
    this.style = tuiInjectElement().style;
    this.scrollSub = inject(TuiScrollbarService).pipe(takeUntilDestroyed()).subscribe(([top, left]) => {
      this.el.style.scrollBehavior = 'auto';
      this.el.scrollTo({
        top,
        left
      });
      this.el.style.scrollBehavior = '';
    });
    this.styleSub = merge(inject(WA_ANIMATION_FRAME).pipe(throttleTime(100)), tuiScrollFrom(this.el)).pipe(tuiZonefree(inject(NgZone)), takeUntilDestroyed()).subscribe(() => {
      if (this.tuiScrollbar === 'vertical') {
        this.style.top = `${this.thumb * 100}%`;
        this.style.height = `${this.view * 100}%`;
      } else {
        this.style.left = `${this.thumb * 100}%`;
        this.style.width = `${this.view * 100}%`;
      }
    });
    this.tuiScrollbar = 'vertical';
  }
  get scrolled() {
    const {
      scrollTop,
      scrollHeight,
      clientHeight,
      scrollLeft,
      scrollWidth,
      clientWidth
    } = this.el;
    return this.tuiScrollbar === 'vertical' ? scrollTop / (scrollHeight - clientHeight) : scrollLeft / (scrollWidth - clientWidth);
  }
  get compensation() {
    const {
      clientHeight,
      scrollHeight,
      clientWidth,
      scrollWidth
    } = this.el;
    if (clientHeight * clientHeight / scrollHeight > MIN_WIDTH && this.tuiScrollbar === 'vertical' || clientWidth * clientWidth / scrollWidth > MIN_WIDTH && this.tuiScrollbar === 'horizontal') {
      return 0;
    }
    return this.tuiScrollbar === 'vertical' ? MIN_WIDTH / clientHeight : MIN_WIDTH / clientWidth;
  }
  get thumb() {
    const compensation = this.compensation || this.view;
    return this.scrolled * (1 - compensation);
  }
  get view() {
    const {
      clientHeight,
      scrollHeight,
      clientWidth,
      scrollWidth
    } = this.el;
    return this.tuiScrollbar === 'vertical' ? Math.ceil(clientHeight / scrollHeight * 100) / 100 : Math.ceil(clientWidth / scrollWidth * 100) / 100;
  }
  static {
    this.ɵfac = function TuiScrollbarDirective_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || TuiScrollbarDirective)();
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: TuiScrollbarDirective,
      selectors: [["", "tuiScrollbar", ""]],
      inputs: {
        tuiScrollbar: "tuiScrollbar"
      },
      standalone: true,
      features: [i0.ɵɵProvidersFeature([TuiScrollbarService])]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiScrollbarDirective, [{
    type: Directive,
    args: [{
      standalone: true,
      selector: '[tuiScrollbar]',
      providers: [TuiScrollbarService]
    }]
  }], null, {
    tuiScrollbar: [{
      type: Input
    }]
  });
})();
class TuiScrollControls {
  constructor() {
    this.scrollRef = inject(TUI_SCROLL_REF).nativeElement;
    this.options = tuiToAnimationOptions(inject(TUI_ANIMATIONS_SPEED));
    this.refresh$ = inject(WA_ANIMATION_FRAME).pipe(throttleTime(300), map(() => this.scrollbars), startWith([false, false]), distinctUntilChanged((a, b) => a[0] === b[0] && a[1] === b[1]), tuiZoneOptimized(inject(NgZone)));
  }
  get scrollbars() {
    const {
      clientHeight,
      scrollHeight,
      clientWidth,
      scrollWidth
    } = this.scrollRef;
    return [Math.ceil(clientHeight / scrollHeight * 100) < 100, Math.ceil(clientWidth / scrollWidth * 100) < 100];
  }
  static {
    this.ɵfac = function TuiScrollControls_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || TuiScrollControls)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: TuiScrollControls,
      selectors: [["tui-scroll-controls"]],
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      decls: 2,
      vars: 3,
      consts: [[4, "ngIf"], ["class", "t-bar t-bar_vertical", 3, "t-bar_has-horizontal", 4, "ngIf"], ["class", "t-bar t-bar_horizontal", 3, "t-bar_has-vertical", 4, "ngIf"], [1, "t-bar", "t-bar_vertical"], ["tuiScrollbar", "vertical", 1, "t-thumb"], [1, "t-bar", "t-bar_horizontal"], ["tuiScrollbar", "horizontal", 1, "t-thumb"]],
      template: function TuiScrollControls_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵtemplate(0, TuiScrollControls_ng_container_0_Template, 3, 2, "ng-container", 0);
          i0.ɵɵpipe(1, "async");
        }
        if (rf & 2) {
          i0.ɵɵproperty("ngIf", i0.ɵɵpipeBind1(1, 1, ctx.refresh$));
        }
      },
      dependencies: [NgIf, AsyncPipe, TuiScrollbarDirective],
      styles: ["[_nghost-%COMP%]{position:sticky;top:0;left:0;z-index:1;min-width:calc(100% - 1px);min-height:calc(100% - 1px);max-width:calc(100% - 1px);max-height:calc(100% - 1px);float:left;margin-inline-end:calc(-100% + 1px);pointer-events:none}.t-bar[_ngcontent-%COMP%]{position:absolute;right:0;bottom:0;pointer-events:auto}.t-bar_vertical[_ngcontent-%COMP%]{top:0;width:.875rem}.t-bar_horizontal[_ngcontent-%COMP%]{left:0;height:.875rem}.t-bar_has-horizontal[_ngcontent-%COMP%]{bottom:.5rem}.t-bar_has-vertical[_ngcontent-%COMP%]{right:.5rem}.t-thumb[_ngcontent-%COMP%]{transition-property:all;transition-duration:.15s;transition-timing-function:ease-in-out;position:absolute;border-radius:6.25rem;border:.25rem solid transparent;cursor:pointer;pointer-events:auto;-webkit-user-select:none;user-select:none;background:currentColor;background-clip:content-box;box-sizing:border-box;transition-property:width,height,opacity;opacity:.2}.t-thumb[_ngcontent-%COMP%]:hover{opacity:.24}.t-thumb[_ngcontent-%COMP%]:active{opacity:.48}.t-bar_vertical[_ngcontent-%COMP%]   .t-thumb[_ngcontent-%COMP%]{right:0;width:.75rem;min-height:1.25rem}.t-bar_vertical[_ngcontent-%COMP%]:hover   .t-thumb[_ngcontent-%COMP%], .t-bar_vertical[_ngcontent-%COMP%]   .t-thumb[_ngcontent-%COMP%]:active{width:.875rem}.t-bar_horizontal[_ngcontent-%COMP%]   .t-thumb[_ngcontent-%COMP%]{bottom:0;height:.75rem;min-width:1.25rem}.t-bar_horizontal[_ngcontent-%COMP%]:hover   .t-thumb[_ngcontent-%COMP%], .t-bar_horizontal[_ngcontent-%COMP%]   .t-thumb[_ngcontent-%COMP%]:active{height:.875rem}"],
      data: {
        animation: [tuiFadeIn]
      },
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiScrollControls, [{
    type: Component,
    args: [{
      standalone: true,
      selector: 'tui-scroll-controls',
      imports: [NgIf, AsyncPipe, TuiScrollbarDirective],
      changeDetection: ChangeDetectionStrategy.OnPush,
      animations: [tuiFadeIn],
      template: "<ng-container *ngIf=\"refresh$ | async as bars\">\n    <div\n        *ngIf=\"bars[0]\"\n        class=\"t-bar t-bar_vertical\"\n        [@tuiFadeIn]=\"options\"\n        [class.t-bar_has-horizontal]=\"bars[1]\"\n    >\n        <div\n            tuiScrollbar=\"vertical\"\n            class=\"t-thumb\"\n        ></div>\n    </div>\n    <div\n        *ngIf=\"bars[1]\"\n        class=\"t-bar t-bar_horizontal\"\n        [@tuiFadeIn]=\"options\"\n        [class.t-bar_has-vertical]=\"bars[0]\"\n    >\n        <div\n            tuiScrollbar=\"horizontal\"\n            class=\"t-thumb\"\n        ></div>\n    </div>\n</ng-container>\n",
      styles: [":host{position:sticky;top:0;left:0;z-index:1;min-width:calc(100% - 1px);min-height:calc(100% - 1px);max-width:calc(100% - 1px);max-height:calc(100% - 1px);float:left;margin-inline-end:calc(-100% + 1px);pointer-events:none}.t-bar{position:absolute;right:0;bottom:0;pointer-events:auto}.t-bar_vertical{top:0;width:.875rem}.t-bar_horizontal{left:0;height:.875rem}.t-bar_has-horizontal{bottom:.5rem}.t-bar_has-vertical{right:.5rem}.t-thumb{transition-property:all;transition-duration:.15s;transition-timing-function:ease-in-out;position:absolute;border-radius:6.25rem;border:.25rem solid transparent;cursor:pointer;pointer-events:auto;-webkit-user-select:none;user-select:none;background:currentColor;background-clip:content-box;box-sizing:border-box;transition-property:width,height,opacity;opacity:.2}.t-thumb:hover{opacity:.24}.t-thumb:active{opacity:.48}.t-bar_vertical .t-thumb{right:0;width:.75rem;min-height:1.25rem}.t-bar_vertical:hover .t-thumb,.t-bar_vertical .t-thumb:active{width:.875rem}.t-bar_horizontal .t-thumb{bottom:0;height:.75rem;min-width:1.25rem}.t-bar_horizontal:hover .t-thumb,.t-bar_horizontal .t-thumb:active{height:.875rem}\n"]
    }]
  }], null, null);
})();
const TUI_DEFAULT_SCROLLBAR_OPTIONS = {
  mode: 'always'
};
const TUI_SCROLLBAR_OPTIONS = tuiCreateToken(TUI_DEFAULT_SCROLLBAR_OPTIONS);
function tuiScrollbarOptionsProvider(options) {
  return tuiProvideOptions(TUI_SCROLLBAR_OPTIONS, options, TUI_DEFAULT_SCROLLBAR_OPTIONS);
}

/**
 * An event for scrolling an element into view within {@link TuiScrollbar}.
 */
const TUI_SCROLL_INTO_VIEW = 'tui-scroll-into-view';
/**
 * An event to notify {@link TuiScrollbar} that
 * it should control a nested element.
 */
const TUI_SCROLLABLE = 'tui-scrollable';
class TuiScrollbar {
  constructor() {
    this.el = tuiInjectElement();
    this.options = inject(TUI_SCROLLBAR_OPTIONS);
    this.isIOS = inject(TUI_IS_IOS);
    this.browserScrollRef = new ElementRef(this.el);
    this.hidden = false;
  }
  get delegated() {
    return this.browserScrollRef.nativeElement !== this.el;
  }
  onScrollable(element) {
    this.browserScrollRef.nativeElement = element;
  }
  scrollIntoView(detail) {
    if (this.delegated) {
      return;
    }
    const {
      nativeElement
    } = this.browserScrollRef;
    const {
      offsetTop,
      offsetLeft
    } = tuiGetElementOffset(nativeElement, detail);
    const {
      clientHeight,
      clientWidth
    } = nativeElement;
    const {
      offsetHeight,
      offsetWidth
    } = detail;
    const scrollTop = offsetTop + offsetHeight / 2 - clientHeight / 2;
    const scrollLeft = offsetLeft + offsetWidth / 2 - clientWidth / 2;
    nativeElement.scrollTo?.(scrollLeft, scrollTop);
  }
  static {
    this.ɵfac = function TuiScrollbar_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || TuiScrollbar)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: TuiScrollbar,
      selectors: [["tui-scrollbar"]],
      hostVars: 2,
      hostBindings: function TuiScrollbar_HostBindings(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵlistener("tui-scrollable.stop", function TuiScrollbar_tui_scrollable_stop_HostBindingHandler($event) {
            return ctx.onScrollable($event.detail);
          })("tui-scroll-into-view.stop", function TuiScrollbar_tui_scroll_into_view_stop_HostBindingHandler($event) {
            return ctx.scrollIntoView($event.detail);
          });
        }
        if (rf & 2) {
          i0.ɵɵclassProp("_native-hidden", !ctx.isIOS || ctx.hidden);
        }
      },
      inputs: {
        hidden: "hidden"
      },
      standalone: true,
      features: [i0.ɵɵProvidersFeature([{
        provide: TUI_SCROLL_REF,
        useFactory: () => inject(TuiScrollbar).browserScrollRef
      }]), i0.ɵɵStandaloneFeature],
      ngContentSelectors: _c0,
      decls: 3,
      vars: 3,
      consts: [["class", "t-bars", 3, "t-hover-mode", 4, "ngIf"], [1, "t-content"], [1, "t-bars"]],
      template: function TuiScrollbar_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵtemplate(0, TuiScrollbar_tui_scroll_controls_0_Template, 1, 2, "tui-scroll-controls", 0);
          i0.ɵɵelementStart(1, "div", 1);
          i0.ɵɵprojection(2);
          i0.ɵɵelementEnd();
        }
        if (rf & 2) {
          i0.ɵɵproperty("ngIf", !ctx.hidden && !ctx.isIOS);
          i0.ɵɵadvance();
          i0.ɵɵclassProp("t-content_delegated", ctx.delegated);
        }
      },
      dependencies: [TuiScrollControls, NgIf],
      styles: ["[_nghost-%COMP%]{position:relative;display:flex;isolation:isolate;overflow:auto}._native-hidden[_nghost-%COMP%]{scrollbar-width:none;-ms-overflow-style:none}._native-hidden[_nghost-%COMP%]::-webkit-scrollbar, ._native-hidden[_nghost-%COMP%]::-webkit-scrollbar-thumb{display:none}[_nghost-%COMP%]   .t-hover-mode[_ngcontent-%COMP%]:not(:active){transition-property:opacity;transition-duration:var(--tui-duration, .3s);transition-timing-function:ease-in-out;opacity:0}[_nghost-%COMP%]:hover   .t-hover-mode[_ngcontent-%COMP%]{transition-property:opacity;transition-duration:var(--tui-duration, .3s);transition-timing-function:ease-in-out;opacity:1}.t-content[_ngcontent-%COMP%]{isolation:isolate;flex:1;flex-basis:auto;width:100%;height:-webkit-max-content;height:max-content}.t-content_delegated[_ngcontent-%COMP%]{height:100%}.t-bars[_ngcontent-%COMP%]{color:var(--tui-text-primary)}"],
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiScrollbar, [{
    type: Component,
    args: [{
      standalone: true,
      selector: 'tui-scrollbar',
      imports: [TuiScrollControls, NgIf],
      changeDetection: ChangeDetectionStrategy.OnPush,
      providers: [{
        provide: TUI_SCROLL_REF,
        useFactory: () => inject(TuiScrollbar).browserScrollRef
      }],
      host: {
        '[class._native-hidden]': '!isIOS || hidden'
      },
      template: "<tui-scroll-controls\n    *ngIf=\"!hidden && !isIOS\"\n    class=\"t-bars\"\n    [class.t-hover-mode]=\"options.mode === 'hover'\"\n/>\n<div\n    class=\"t-content\"\n    [class.t-content_delegated]=\"delegated\"\n>\n    <ng-content />\n</div>\n",
      styles: [":host{position:relative;display:flex;isolation:isolate;overflow:auto}:host._native-hidden{scrollbar-width:none;-ms-overflow-style:none}:host._native-hidden::-webkit-scrollbar,:host._native-hidden::-webkit-scrollbar-thumb{display:none}:host .t-hover-mode:not(:active){transition-property:opacity;transition-duration:var(--tui-duration, .3s);transition-timing-function:ease-in-out;opacity:0}:host:hover .t-hover-mode{transition-property:opacity;transition-duration:var(--tui-duration, .3s);transition-timing-function:ease-in-out;opacity:1}.t-content{isolation:isolate;flex:1;flex-basis:auto;width:100%;height:-webkit-max-content;height:max-content}.t-content_delegated{height:100%}.t-bars{color:var(--tui-text-primary)}\n"]
    }]
  }], null, {
    hidden: [{
      type: Input
    }],
    onScrollable: [{
      type: HostListener,
      args: [`${TUI_SCROLLABLE}.stop`, ['$event.detail']]
    }],
    scrollIntoView: [{
      type: HostListener,
      args: [`${TUI_SCROLL_INTO_VIEW}.stop`, ['$event.detail']]
    }]
  });
})();

/**
 * Directive scrolls element into view inside tui-scrollbar
 */
class TuiScrollIntoView {
  constructor() {
    this.el = tuiInjectElement();
    this.destroyRef = inject(DestroyRef);
  }
  set tuiScrollIntoView(scroll) {
    if (!scroll) {
      return;
    }
    // Timeout is necessary in order to give element render cycle to get into its final spot
    // (for example if it is inside dropdown box which has to be positioned first)
    timer(0).pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      this.el.dispatchEvent(new CustomEvent(TUI_SCROLL_INTO_VIEW, {
        bubbles: true,
        detail: this.el
      }));
    });
  }
  static {
    this.ɵfac = function TuiScrollIntoView_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || TuiScrollIntoView)();
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: TuiScrollIntoView,
      selectors: [["", "tuiScrollIntoView", ""]],
      inputs: {
        tuiScrollIntoView: "tuiScrollIntoView"
      },
      standalone: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiScrollIntoView, [{
    type: Directive,
    args: [{
      standalone: true,
      selector: '[tuiScrollIntoView]'
    }]
  }], null, {
    tuiScrollIntoView: [{
      type: Input
    }]
  });
})();
const SCROLL_REF_SELECTOR = '[tuiScrollRef]';
class TuiScrollRef {
  static {
    this.ɵfac = function TuiScrollRef_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || TuiScrollRef)();
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: TuiScrollRef,
      selectors: [["", "tuiScrollRef", ""]],
      standalone: true,
      features: [i0.ɵɵProvidersFeature([tuiProvide(TUI_SCROLL_REF, ElementRef)])]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiScrollRef, [{
    type: Directive,
    args: [{
      standalone: true,
      selector: SCROLL_REF_SELECTOR,
      providers: [tuiProvide(TUI_SCROLL_REF, ElementRef)]
    }]
  }], null, null);
})();
class TuiScrollable {
  constructor() {
    this.el = tuiInjectElement();
  }
  ngOnInit() {
    this.el.dispatchEvent(new CustomEvent(TUI_SCROLLABLE, {
      bubbles: true,
      detail: this.el
    }));
  }
  static {
    this.ɵfac = function TuiScrollable_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || TuiScrollable)();
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: TuiScrollable,
      selectors: [["", "tuiScrollable", ""]],
      standalone: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiScrollable, [{
    type: Directive,
    args: [{
      standalone: true,
      selector: '[tuiScrollable]'
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { SCROLL_REF_SELECTOR, TUI_DEFAULT_SCROLLBAR_OPTIONS, TUI_SCROLLABLE, TUI_SCROLLBAR_OPTIONS, TUI_SCROLL_INTO_VIEW, TuiScrollControls, TuiScrollIntoView, TuiScrollRef, TuiScrollable, TuiScrollbar, TuiScrollbarDirective, TuiScrollbarService, tuiScrollbarOptionsProvider };
